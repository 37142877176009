import React, { useEffect, useState } from "react";
import { getAllGyms } from "../../helperFunctions/GymFormHelper";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useNavigate } from "react-router-dom";
import { fetchAllGyms } from "../MarketplaceHelpers/MarketplaceHelper";


const SearchDropDown = ({ setSearchQuery, searchQuery, setSearchDropDown, cityLocation }) => {
  const navigate = useNavigate();
  
  const [gymList, setGymList] = useState([]);
  const [filteredGyms, setFilteredGyms] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllGyms(cityLocation);
      setGymList(data);
    };
    fetchData();
  }, [searchQuery, gymList]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      setFilteredGyms([]);
    } else {
      const filtered = gymList.filter((gym) =>
        gym.gymDisplayName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredGyms(filtered.slice(0, 5)); // Limit to 5 results
    }
  }, [searchQuery, gymList]);

  const handleGymClick = (gym) => {
    // Redirect to the gym page
    console.log("Gym: ", gym);
    
    setSearchDropDown(false);
    // Navigate to gym page : name+locality
    navigate(`/gym/${gym.city.toLowerCase()}-${gym.gymName.toLowerCase()}`, {
      state: { city: gym.city, gymName: gym.gymName } // changed from state: { id: gym.gymID } to state: { city: gym.city, gymName: gym.gymName } and it works now
    });
}

  return (
    <div className="absolute top-8 left-0 w-full z-10 overflow-visible">
      {searchQuery.length === 0 ? (
        <div className="bg-white border border-secondary rounded-md shadow-lg w-full">
          <div className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
            Start Typing.....
          </div>
        </div>
      ) : (
        filteredGyms.map((gym) => (
          <div
            key={gym.id}
            className="bg-white border border-secondary rounded-md shadow-lg w-full"
          >
            <div>
              <div
                key={gym.id}
                className="px-4 py-2 hover:bg-tertiary cursor-pointer hover:rounded-md flex justify-between items-center"
                onClick={() => {handleGymClick(gym); setSearchQuery('');}}
              >
                <div className="text-start">
                  <div className="font-semibold">{gym.gymDisplayName}</div>
                  <div className="text-sm">{gym.city.charAt(0).toUpperCase() + gym.city.slice(1)}</div>
                </div>

                <ArrowCircleRightIcon className="text-secondary"/>

              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SearchDropDown;
