import match from "../assets/Website_content/TR_Website_content-05.svg";
import track from "../assets/Website_content/TR_Website_content-07.svg";
import consult from "../assets/Website_content/TR_Website_content-09.svg";

export const MonthlyExpertPlan_Modal = (props) => (
  <div className="relative flex flex-col justify-center bg-bg p-4 lg:p-8 rounded-3xl">
    {/* Cancel Button */}
    <button
      onClick={() => props.setShowRexpertPlan(false)}
      className="absolute top-2 right-6 text-white text-4xl lg:text-4xl font-bold hover:text-red-800 transition"
    >
      &times;
    </button>

    <p className="text-center text-secondary text-2xl lg:text-3xl font-bold pb-4">TRAIN REX</p>
    <p className="text-center text-tertiary text-2xl lg:text-3xl font-bold pb-8">Consultation Plans</p>

    <div className="flex flex-row gap-x-2 lg:gap-x-8 justify-between">
      <button className="flex flex-col bg-tertiary px-4 lg:px-8 py-4 rounded-2xl items-center">
        <p className="text-center text-primary text-[15px] lg:text-lg font-semibold">1 month</p>
        <p className="text-center text-secondary text-xl lg:text-2xl font-semibold">
          <span className="line-through decoration-red">7999</span>
        </p>
        <p className="text-center text-primary text-2xl lg:text-3xl font-semibold">6399</p>
      </button>
      <button className="flex flex-col bg-tertiary px-4 lg:px-8 py-4 rounded-2xl items-center">
        <p className="text-center text-primary text-[15px] lg:text-lg font-semibold">3 month</p>
        <p className="text-center text-secondary text-xl lg:text-2xl font-semibold">
          <span className="line-through decoration-red">19999</span>
        </p>
        <p className="text-center text-primary text-2xl lg:text-3xl font-semibold">15999</p>
      </button>
      <button className="flex flex-col bg-tertiary px-4 lg:px-8 py-4 rounded-2xl items-center">
        <p className="text-center text-primary text-[15px] lg:text-lg font-semibold">6 month</p>
        <p className="text-center text-secondary text-xl lg:text-2xl font-semibold">
          <span className="line-through decoration-red">34999</span>
        </p>
        <p className="text-center text-primary text-2xl lg:text-3xl font-semibold">27999</p>
      </button>
    </div>
    <div className="flex flex-row justify-center items-center py-8">
      <p className="text-center text-red text-3xl font-bold">FLAT 20%</p>
      <p className="text-center text-red text-sm font-bold pt-3">OFF</p>
    </div>

    <div className="flex mb-4 gap-x-1 justify-center">
      {/* First SVG */}
      <img src={match} alt="Match" className="h-6 lg:h-8" />
      {/* Second SVG */}
      <img src={track} alt="Track" className="h-6 lg:h-8" />
      {/* Third SVG */}
      <img src={consult} alt="Consult" className="h-6 lg:h-8" />
    </div>
  </div>
);
