import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getSingleGym } from "../helperFunctions/GymFormHelper";
import { Carousel } from "react-responsive-carousel";
import { UserLoginModal } from "../helperFunctions/ModalHelpers/UserCreds";
import { BigButton } from "../helperFunctions/MarketplaceHelpers/ButtonsLibrary";
// import { reviews } from "../assets/Strings";
import { Link, animateScroll as scroll, scroller } from "react-scroll";
import { themeColors } from "../theme";
import { NearYou } from "../helperFunctions/MarketplaceHelpers/NearYou";
import {
    ClickOpen,
    ListDisplay,
} from "../helperFunctions/MarketplaceHelpers/ButtonsLibrary";
import GymPageSkeleton from "../components/gymPageSkeleton";
import convertDriveUrl from "../helperFunctions/imgUrlRecontuct";

import useAuthStatus from "../hooks/clientAuth";
import { handlePayment } from "../helperFunctions/RazorpayHelper";

import { fetchGymReviews } from "../helperFunctions/MarketplaceHelpers/gymDataHelpers/HandlingReviews";
import { getUserCity } from "../helperFunctions/LocationHelpers";
import { fetchGymData } from "../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { sampleReviews } from "../assets/Strings";

import { getGymMachineryList } from "../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymMachineryHelper";
import GymDetailsComponent from "../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymDetailsComponent";
import AddReviewModal from "../helperFunctions/AddReviewModal.js";
import { getDaysAgo, isRecentGymUser } from "../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymReviewsHelper.js"
import StarRating from "../components/StarRating.js";
import verified from "../assets/Verified.png"

import { leadGeneration } from "../helperFunctions/MarketplaceHelpers/gymDataHelpers/LeadGeneration.js";
import { DisplayPrice, getListOfSellingPrices } from "../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers.js";

/**
   * Portfolio Page of a gym
   * The params should be taken from the previous page
   * OR
   * shall take it from the url (To be Configured)
   * @param {String} gymName  - Name of the Gym
   * @param {String} city     - City of the Gym
   * @returns {JSX.Element}   - Renders to portfolio page of the gym
*/
const GymPage = () => {
    // const { gymName2 } = useParams();
    const location = useLocation();
    const { gymSlug } = useParams(); // extract the gymSlug from the URL

    // Extract state passed from the previous page
    const { city: stateCity, gymName: stateGymName } = location.state || {};

    // If state is not available, fall back to gymSlug
    let city, gymName;

    if (stateCity && stateGymName) {
        // If state is available, use it
        city = stateCity;
        gymName = stateGymName;
    } else if (gymSlug) {
        // If state is not available, extract from gymSlug
        const slugParts = gymSlug.split('-');
        city = slugParts[0]; // City is the first part of the slug
        gymName = slugParts.slice(1).join(' '); // Gym name is the rest of the slug
    }

    const [currGymData, setCurrGymData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [signInModal, setSignInModal] = useState(false);
    const [myData, setMyData] = useState({});
    const [loading, setLoading] = useState(true);
    const [pendingPayment, setPendingPayment] = useState(false); // Tracks pending payment after sign-in

    const { isUserPresent, user } = useAuthStatus();

    const [reviews, setReviews] = useState({});
    const [visibleReviews, setVisibleReviews] = useState(4);

    const timeLabels = ["6am", "10am", "2pm", "6pm", "10pm"];
    const barHeights = [8, 16, 32, 8, 16, 32, 8, 16, 32, 8, 16, 32, 8, 16, 32, 8];
    const [showAddReviewModal, setShowReviewModal] = useState(false);
    const [averageRating, setAverageRating] = useState();
    const [exclusivePrices, setExclusivePrices] = useState({
        dailyPrice          :   0,
        monthlyPrice        :   0,
        quarterlyPrice      :   0,
        halfYearlyPrice     :   0,
        yearlyPrice         :   0,
    })

    const [sellingPrices, setSellingPrices] = useState({
        dailyPrice          :   0,
        monthlyPrice        :   0,
        quarterlyPrice      :   0,
        halfYearlyPrice     :   0,
        yearlyPrice         :   0,
    })

    const [mrp, setMRP] = useState({
        dailyPrice          :   0,
        monthlyPrice        :   0,
        quarterlyPrice      :   0,
        halfYearlyPrice     :   0,
        yearlyPrice         :   0,
    })

    let isUserRecent = false;

    // These are gyms for which we don't want any changes in the database while testing
    const filteredGyms = [
        'gypsy',
        'csrthefitnessclub',
        'halexfitness',
        'marutgymandfitnesscenter'
    ]

    // To fetch gym Data and verify recent gym member
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchGymData(city, gymName);
                if (data) {
                    setCurrGymData(data);

                    setExclusivePrices({
                        dailyPrice          :   data.exclusiveDailyPrice,
                        monthlyPrice        :   data.exclusiveMonthlyPrice,
                        quarterlyPrice      :   data.exclusiveQuarterlyPrice,
                        halfYearlyPrice     :   data.exclusiveHalfYearlyPrice,
                        yearlyPrice         :   data.exclusiveYearlyPrice,
                    })

                    setMRP({
                        dailyPrice          :   data.dailyPrice,
                        monthlyPrice        :   data.monthlyPrice,
                        quarterlyPrice      :   data.quarterlyPrice,
                        halfYearlyPrice     :   data.halfYearlyPrice,
                        yearlyPrice         :   data.yearlyPrice,
                    })

                    setSellingPrices(
                        await getListOfSellingPrices({
                                dailyPrice          :   data.exclusiveDailyPrice,
                                monthlyPrice        :   data.exclusiveMonthlyPrice,
                                quarterlyPrice      :   data.exclusiveQuarterlyPrice,
                                halfYearlyPrice     :   data.exclusiveHalfYearlyPrice,
                                yearlyPrice         :   data.exclusiveYearlyPrice,
                        })
                    );

                    // console.log('Prices : ', currGymData);

                    // Check if the user has been a recent member of this gym
                    isUserRecent = await isRecentGymUser(currGymData);
                    if (isUserRecent) {
                        // console.log("User has recently been a member of this gym.");
                    } else {
                        // console.log("User is not a recent member of this gym.");
                    }
                }
            } catch (error) {
                // console.error("Error fetching gym data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [gymName]);

    // Lead Generation logic
    useEffect(() => {
        const handleLeadGeneration = async () => {
            try {
                await leadGeneration(user, currGymData);
            } catch (error) {
                // console.error("Error generating lead:", error);
            }
        };

        handleLeadGeneration();
    }, [isUserPresent, user, gymName, city]);

    // to fetch gym reviews
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const fetchedReviews = await fetchGymReviews(currGymData);
                setReviews(fetchedReviews);

                // Calculate average rating
                if (fetchedReviews.length > 0) {
                    const totalRating = fetchedReviews.reduce((acc, review) => acc + review.rating, 0);
                    const averageRating = totalRating / fetchedReviews.length;
                    setAverageRating(averageRating.toFixed(1)); // Set average rating and round to 1 decimal place
                } else {
                    setAverageRating(0); // If no reviews, set average rating to 0
                }

            } catch (error) {
                // console.error("Error fetching reviews:", error);
            }
        };

        fetchReviews();
    }, [currGymData]);

    useEffect(() => {
        if (!isUserPresent && showAddReviewModal) {
            setSignInModal(true);
        }
    }, [isUserPresent, showAddReviewModal]);

    const openModal = (index) => {
        setSelectedImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    /**
       * Handles membership payment and user sign-in flow.
       * If the user is not signed in, shows the sign-in modal. 
       * If the user is signed in, initiates payment directly.
       * @param {number} months - Duration of the membership in months.
       * @param {number} amount - Amount to be paid for the membership.
    */
    const handleMembershipButton = async (months, amount) => {
        const gymDataForPayment = {
            gymName         : currGymData.name,
            city            : currGymData?.city,
            months          : months,
            amount          : amount * 100, // Convert to smallest currency unit (e.g., cents)
            image           : currGymData.logo,
            gymContactNo    : currGymData.gymContactNo || '',
            address         : currGymData.address,
            cityDisplayName : currGymData.cityDisplayName,
            gstin           : currGymData.gstin,
            gymDisplayName  : currGymData.gymDisplayName,
            gymEmail        : currGymData.email,

        };

        if (!isUserPresent) {
            // Open sign-in modal if user is not present
            setSignInModal(true);
            setMyData(gymDataForPayment);
            setPendingPayment(true); // Set pending payment flag for later payment
        } else {
            // If the user is present, initiate payment
            await handlePayment({ 
                ...gymDataForPayment, 
                user 
            });
        }
    };

    // Automatically handle payment after user signs in if a payment is pending
    useEffect(() => {
        if (pendingPayment && isUserPresent) {
            handlePayment({ 
                ...myData, 
                user 
            });
            setPendingPayment(false); // Reset the pending payment flag after payment
        }
    }, [isUserPresent, myData, pendingPayment, user]);

    const showMoreReviews = () => {
        setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 3);
    };

    const showLessReviews = () => {
        setVisibleReviews(3);
    };

    const scrollToSection = (sectionId) => {
        scroller.scrollTo(sectionId, {
            duration: 800,
            delay: 10,
            smooth: "easeInOutQuart",
            offset: -150,
        });
    };

    if (loading) {
        return <GymPageSkeleton />;
    }

    return (
        <div>
            {currGymData ? (
                <div className="bg-tertiary flex flex-col px-8 lg:px-48 pt-48">
                    {/* Image Section */}
                    {Array.isArray(currGymData.Portfolio) ?
                        <div className="flex flex-row h-96 bg-secondary border-4 border-secondary rounded-2xl">
                            <div className="relative flex h-full lg:h-84 w-full border-2 border-secondary rounded-2xl overflow-hidden">
                                <img
                                    src={convertDriveUrl(currGymData.Portfolio[0])} // Display the first image
                                    alt="Logo"
                                    className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                    onClick={() => openModal(0)}
                                />
                                <div className="absolute bottom-0 right-0 bg-secondary bg-opacity-100 rounded-xl lg:hidden flex justify-between p-2">
                                    <p className="flex text-tertiary text-xl font-bold">
                                        + {currGymData.Portfolio.length} photos
                                    </p>
                                </div>
                            </div>

                            {/* Display images in a grid when screen size is large */}
                            <div className="hidden lg:flex flex-col border-2 border-secondary rounded-2xl w-full">
                                <div className="flex h-56 overflow-hidden">
                                    {currGymData.Portfolio.slice(1, 2).map((image, index) => (
                                        <img
                                            key={index}
                                            src={convertDriveUrl(image)}
                                            alt="Portfolio Image"
                                            className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                            onClick={() => openModal(index + 1)}
                                        />
                                    ))}
                                </div>
                                <div className="flex flex-row w-full">
                                    {currGymData.Portfolio.slice(2, 4).map((image, index) => (
                                        <div className="flex h-36 w-full overflow-hidden" key={index}>
                                            <img
                                                src={convertDriveUrl(image)}
                                                alt="Portfolio Image"
                                                className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                                onClick={() => openModal(index + 2)}
                                            />
                                        </div>
                                    ))}
                                    {currGymData.Portfolio.length > 4 && (
                                        <div className="relative flex h-36 w-full overflow-hidden">
                                            <img
                                                src={convertDriveUrl(currGymData.Portfolio[3])}
                                                alt="Logo"
                                                className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                                onClick={() => openModal(3)}
                                            />
                                            <div className="absolute bottom-0 right-0 bg-secondary bg-opacity-100 rounded-xl hidden lg:flex justify-between p-2">
                                                <p className="flex text-tertiary text-xl font-bold">
                                                    + {currGymData.Portfolio.length - 4} photos
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="flex flex-row h-96 bg-secondary border-4 border-secondary rounded-2xl">
                            No Images Available
                        </div>
                    }

                    {isModalOpen && (
                        <div className="flex inset-0 fixed justify-center bg-black bg-opacity-50 z-50 pt-36 lg:p-64 mb-auto lg:-mt-48">
                            <div className="relative p-4 rounded-xl">
                                <button
                                    className="absolute -top-5 lg:-top-9 right-4 lg:right-4 text-tertiary text-4xl lg:text-6xl h-8"
                                    onClick={closeModal}
                                >
                                    &times;
                                </button>
                                <div className="max-h-[80vh] max-w-[75vw] overflow-hidden">
                                    <Carousel
                                        selectedItem={selectedImageIndex}
                                        showThumbs={true}
                                        className="object-cover items-center"                >
                                        {currGymData.Portfolio.map((img, index) => (
                                            <div key={index}>
                                                <img src={convertDriveUrl(img)} alt={`Image ${index}`} />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    )}

                    <UserLoginModal
                        modalIsOpen={signInModal}
                        setModalIsOpen={setSignInModal}
                        myData={myData}
                    />

                    {/* <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content" overlayClassName="modal-overlay">
                <Slider {...settings}>
                    {data.image.map((img, index) => (
                        <div key={index}>
                            <img src={img} alt={`Slide ${index}`} className="w-full rounded-2xl" />
                        </div>
                    ))}
                </Slider>
            </Modal> */}

                    {/* ------------------------------- */}
                    {/* ----Gym Information Section---- */}
                    {/* ------------------------------- */}
                    <div className="flex flex-col justify-between">
                        <div className="flex flex-row justify-between pt-8 pb-2">
                            <div className="flex flex-col justify-start">
                                <div className="flex gap-8">
                                    <p className="flex text-4xl lg:text-6xl font-bold text-secondary">
                                        {currGymData?.gymDisplayName || currGymData.name}
                                    </p>
                                    {currGymData.trainRexVerified ? <img src={verified} className="h-14 w-14 mt-1 rounded-full" /> : ''}
                                </div>
                                <div className="flex pt-4">
                                    <p className="flex text-2xl lg:text-4xl font-bold text-secondary">
                                        {currGymData?.cityDisplayName}{" "}
                                    </p>
                                </div>
                            </div>

                            {/* Review and Ratings */}
                            <div className="flex flex-col">
                                <div className="hidden lg:flex flex-col items-end">
                                    <div className="text-lg lg:text-3xl text-secondary font-bold">
                                        <StarRating rating={currGymData?.rating} NoOfReview={currGymData?.NoOfReview} color={'secondary'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between">
                            <div className="flex flex-col">
                                <div className="flex flex-col py-4">
                                    <p className="flex text-[15px] lg:text-xl font-bold text-black">
                                        Address
                                    </p>
                                    <p className="flex text-[15px] lg:text-xl font-bold text-secondary">
                                        {currGymData?.address}
                                    </p>
                                    <p className="flex text-[15px] lg:text-xl font-bold text-secondary">
                                        {currGymData?.locality},
                                    </p>
                                    <p className="flex text-[15px] lg:text-xl font-bold text-secondary">
                                        {currGymData?.cityDisplayName}, {currGymData?.pinCode}
                                    </p>
                                </div>
                                <div className="flex flex-col mb-4">
                                    <p className="flex text-[15px] lg:text-xl font-bold text-black">
                                        Timing
                                    </p>
                                    <p className="flex text-[15px] lg:text-xl font-bold text-secondary">
                                        {currGymData?.openingTime} to {currGymData?.closingTime}
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col bg-secondary px-4 pt-2 px-10 rounded-2xl items-center mt-4 pb-2 lg:pb-0 mb-2 lg:mb-8">
                                <p className="flex text-sm font-semibold text-black p-2">
                                    Liked {currGymData.gymDisplayName}?{" "}
                                </p>
                                <BigButton
                                    label="BOOK A FREE TRIAL"
                                    bg="bg-tertiary"
                                    textColor="text-secondary"
                                    width="w-full"
                                />
                                <BigButton
                                    label="BUY MEMBERSHIP"
                                    onClick={() => scrollToSection("membership-section")}
                                    bg="bg-tertiary"
                                    textColor="text-secondary"
                                    width="w-full"
                                />
                                {/* <div> 
                            <PaymentComponent />
                        </div> */}
                                <BigButton
                                    label={`Call ${currGymData.phoneNumber}`}
                                    bg="bg-tertiary"
                                    textColor="text-secondary"
                                    width="w-full"
                                    className={`${currGymData.phoneNumber ? 'block' : 'hidden'}`}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        id="membership-section"
                        className="flex flex-col lg:flex-row justify-between mb-8 mt-4 lg:gap-x-6"
                    >
                        {/* -------------------------------- */}
                        {/* ------ Rating and Reviews ------ */}
                        {/* -------------------------------- */}
                        <div className="flex flex-col justify-start lg:border-4 border-secondary rounded-2xl lg:w-3/5">
                            <div className="flex justify-between items-center mr-2">
                                <p className="flex lg:px-4 py-4 pb-2 text-secondary text-xl lg:text-3xl font-bold text-left">
                                    Rating and Reviews
                                </p>

                                {/* <div className="flex pb-0 lg:px-4">
                        <p className="flex text-secondary text-sm lg:text-xl font-bold">
                            Known for:
                        </p>
                    </div> */}

                                {/* Showing the number of stars rating */}
                                <div>
                                    {(() => {
                                        const ratingCounts = getRatingCounts(reviews);
                                        const totalRatings = reviews.length;
                                        return (
                                            <div className="flex flex-col lg:px-4 py-2">
                                                {Object.keys(ratingCounts).reverse().map(star => (
                                                    <div key={star} className="flex items-center justify-between -mb-1 cursor-default">
                                                        {/* Replace inline star rendering with StarRating component */}
                                                        <StarRating rating={Number(star)} showNoOfReviews={false} color="secondary" />

                                                        <p className="ml-2 text-secondary text-sm lg:text-lg font-semibold">
                                                            : {ratingCounts[star]}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    })()}
                                </div>
                            </div>
                            <div className="flex lg:mx-4 border-t-2 border-secondary lg:my-4"></div>

                            {reviews.length > 0 ?
                                <>
                                    {/* List of reviews */}
                                    <div className="flex flex-col lg:px-4 relative h-[400px] lg:h-[600px] overflow-y-scroll">
                                        {Array.isArray(reviews) && (
                                            reviews.slice(0, visibleReviews).map((review, index) => (
                                                <div key={index} className="flex flex-col mb-4">
                                                    <div className="flex flex-row justify-between py-2">
                                                        <p className="flex text-secondary text-sm lg:text-2xl font-bold">
                                                            {review.userName}
                                                        </p>
                                                        <div className="flex">
                                                            <StarRating rating={review.rating} color={'secondary'} showNoOfReviews={false} />
                                                        </div>
                                                    </div>
                                                    <div className="flex -mt-2 mb-2">
                                                        <div className="text-left text-primary text-xs">
                                                            {getDaysAgo(review.timestamp)}
                                                        </div>
                                                        {review.verified ?
                                                            <>
                                                                <div className="text-base -mt-1 px-1 text-primary"> | </div>
                                                                <span className="text-xs text-secondary mr-1 font-semibold -mb-1">Verified by Train Rex</span><img src={verified} alt="Trusted" className="h-4 w-4" />
                                                            </>
                                                            : ''}
                                                    </div>
                                                    <p className="flex text-black text-sm lg:text-lg text-left whitespace-pre-line">
                                                        {review.review}
                                                    </p>
                                                    <div className="flex border-t-[1px] border-secondary mt-4"></div>
                                                </div>
                                            ))
                                        )}
                                    </div>

                                    {/* <div className="flex lg:mx-4 border-t-2 border-secondary my-4"></div> */}

                                    <div className="flex justify-between lg:justify-center pb-8 lg:pb-2">
                                        {visibleReviews < currGymData.NoOfReview && reviews.length > 0 ? (
                                            <BigButton
                                                label="View More"
                                                bg="bg-secondary"
                                                textColor="text-tertiary"
                                                width="lg:w-1/7"
                                                onClick={showMoreReviews}
                                            />
                                        ) : (
                                            <BigButton
                                                label="View Less"
                                                bg="bg-secondary"
                                                textColor="text-tertiary"
                                                width="lg:w-1/7"
                                                onClick={showLessReviews}
                                            />
                                        )}
                                        <BigButton
                                            label="Add Review"
                                            bg="bg-secondary"
                                            textColor="text-tertiary"
                                            width="w-1/7 flex justify-center"
                                            onClick={() => setShowReviewModal(true)} // Open modal on button click
                                        />
                                        {showAddReviewModal && isUserPresent ? (
                                            <AddReviewModal setShowReviewModal={setShowReviewModal} gymLocation={currGymData.city} gymName={currGymData.gymName} isRecentGymUser={isUserRecent} displayName={user.displayName} />
                                        ) : null}
                                    </div>
                                </>
                                :
                                <div className="relative">
                                    {sampleReviews.slice(0, visibleReviews).map((review, index) => (
                                        <div key={index} className="flex flex-col mb-4">
                                            <div className="flex flex-row gap-x-4 py-2">
                                                <p className="flex text-secondary text-sm lg:text-xl font-bold">
                                                    {review.name}
                                                </p>
                                                <div className="flex">
                                                    {Number.isInteger(parseInt(review.rating)) &&
                                                        [...Array(parseInt(review.rating))].map((_, starIndex) => (
                                                            <span
                                                                key={starIndex}
                                                                className="text-sm lg:text-xl text-secondary"
                                                            >
                                                                &#9733;
                                                            </span>
                                                        ))}
                                                </div>
                                            </div>
                                            <p className="flex text-black text-sm lg:text-lg text-left">
                                                {review.review}
                                            </p>
                                            <div className="flex border-t-[1px] border-secondary mt-4"></div>
                                        </div>
                                    ))}
                                    <div className="absolute inset-0 bg-tertiary bg-opacity-100 flex items-center justify-center text-secondary text-lg lg:text-2xl font-bold flex flex-col space-y-4 rounded-xl">
                                        <p>Be the first one to review</p>
                                        <div className="flex justify-between lg:justify-center pb-8 lg:pb-2">
                                            <BigButton
                                                label="Add Review"
                                                bg="bg-secondary"
                                                textColor="text-tertiary"
                                                width="w-1/7 flex justify-center"
                                                onClick={() => setShowReviewModal(true)} // Open modal on button click
                                            />
                                        </div>
                                        {showAddReviewModal && isUserPresent ? (
                                            <AddReviewModal setShowReviewModal={setShowReviewModal} gymLocation={currGymData.city} gymName={currGymData.gymName} isRecentGymUser={isUserRecent} displayName={user.displayName} />
                                        ) : null}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="flex flex-col justify-between lg:w-2/5 h-fit gap-y-6">
                            {/* -------------------------------- */}
                            {/* ---- Buy Membership Section ---- */}
                            {/* -------------------------------- */}
                            <div className="flex flex-col border-2 lg:border-4 border-secondary rounded-2xl w-full h-fit">
                                <div className="flex flex-row justify-between pt-2 pb-2 lg:pb-0">
                                    <button className="text-primary text-[16px] lg:text-lg font-bold px-4 py-2 focus:bg-secondary focus:rounded-full focus:ml-4 focus:mt-2 focus:px-4 lg:focus:px-5">
                                        Membership Prices
                                    </button>
                                    <button className="text-primary text-[16px] lg:text-lg font-bold px-4 py-2 focus:bg-secondary focus:rounded-full focus:mr-4 focus:mt-2 focus:px-4 lg:focus:px-5">
                                        Traffic Hours
                                    </button>
                                </div>

                                <div className="flex mx-[12px] lg:mx-4 border-t-2 border-secondary lg:my-4"></div>

                                {sellingPrices?.dailyPrice && (
                                    <div>
                                        <div className="flex px-4 py-2 justify-between">
                                            <p className="text-primary text-sm lg:text-xl font-semibold">
                                                Single day
                                            </p>
                                            <button
                                                onClick={() => {
                                                    handleMembershipButton(0, sellingPrices.dailyPrice);
                                                }}
                                                className="text-primary text-sm lg:text-lg font-semibold bg-secondary rounded-full px-4 py-1 lg:py-0 min-w-40 text-center"
                                            >
                                                <DisplayPrice mrp={mrp.dailyPrice} sellingPrice={sellingPrices.dailyPrice} country={currGymData.country} />
                                            </button>
                                        </div>
                                        <div className="flex mx-[12px] lg:mx-4 border-t-[1px] border-secondary lg:my-4"></div>
                                    </div>
                                )}

                                {sellingPrices?.monthlyPrice && (
                                    <div>
                                        <div className="flex px-4 py-2 justify-between">
                                            <p className="text-primary text-sm lg:text-xl font-semibold">
                                                1 month
                                            </p>
                                            <button
                                                onClick={() => {
                                                    handleMembershipButton(1, sellingPrices.monthlyPrice);
                                                }}
                                                className="text-primary text-sm lg:text-lg font-semibold bg-secondary rounded-full px-4 py-1 lg:py-0 min-w-40 text-center"
                                            >
                                                <DisplayPrice mrp={mrp.monthlyPrice} sellingPrice={sellingPrices.monthlyPrice} country={currGymData.country} />
                                            </button>
                                        </div>
                                        <div className="flex mx-[12px] lg:mx-4 border-t-[1px] border-secondary lg:my-4"></div>
                                    </div>
                                )}

                                {sellingPrices?.quarterlyPrice && (
                                    <div>
                                        <div className="flex px-4 py-2 justify-between">
                                            <p className="text-primary text-sm lg:text-xl font-semibold">
                                                3 months
                                            </p>
                                            <button
                                                onClick={() =>
                                                    handleMembershipButton(3, sellingPrices.quarterlyPrice)
                                                }
                                                className="text-primary text-sm lg:text-lg font-semibold bg-secondary rounded-full px-4 py-1 lg:py-0 min-w-40 text-center"
                                            >
                                                <DisplayPrice mrp={mrp.quarterlyPrice} sellingPrice={sellingPrices.quarterlyPrice} country={currGymData.country} />
                                            </button>
                                        </div>
                                        <div className="flex mx-[12px] lg:mx-4 border-t-[1px] border-secondary lg:my-4"></div>
                                    </div>
                                )}

                                {sellingPrices?.halfYearlyPrice && (
                                    <div>
                                        <div className="flex px-4 py-2 justify-between">
                                            <p className="text-primary text-sm lg:text-xl font-semibold">
                                                6 months
                                            </p>
                                            <button
                                                onClick={() =>
                                                    handleMembershipButton(
                                                        6,
                                                        sellingPrices.halfYearlyPrice
                                                    )
                                                }
                                                className="text-primary text-sm lg:text-lg font-semibold bg-secondary rounded-full px-4 py-1 lg:py-0 min-w-40 text-center"
                                            >
                                                <DisplayPrice mrp={mrp.halfYearlyPrice} sellingPrice={sellingPrices.halfYearlyPrice} country={currGymData.country} />
                                            </button>
                                        </div>
                                        <div className="flex mx-[12px] lg:mx-4 border-t-[1px] border-secondary lg:my-4"></div>
                                    </div>
                                )}

                                {sellingPrices?.yearlyPrice && (
                                    <div>
                                        <div className="flex px-4 py-2 lg:pb-8 justify-between">
                                            <p className="text-primary text-sm lg:text-xl font-semibold">
                                                12 months
                                            </p>
                                            <button
                                                onClick={() =>
                                                    handleMembershipButton(12, sellingPrices.yearlyPrice)
                                                }
                                                className="text-primary text-sm lg:text-lg font-semibold bg-secondary rounded-full px-4 py-1 lg:py-0 min-w-40 text-center"
                                            >
                                                <DisplayPrice mrp={mrp.yearlyPrice} sellingPrice={sellingPrices.yearlyPrice} country={currGymData.country} />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Traffic Graph */}
                            <div className="flex flex-col bg-tertiary border-2 lg:border-4 border-secondary rounded-2xl justify-between h-72">
                                <div className="flex flex-row justify-between">
                                    <p className="text-primary text-lg font-bold px-4 pt-4">
                                        Traffic Hours
                                    </p>
                                </div>

                                <div className="flex mx-[12px] lg:mx-4 border-t-2 border-secondary"></div>

                                <div className="flex flex-col relative">
                                    <div className="flex flex-row items-end justify-between pb-2 px-10">
                                        {barHeights.map((height, index) => (
                                            <div
                                                key={index}
                                                className="bg-secondary w-3 lg:w-4 rounded-full"
                                                style={{ height: `${height * 5}px` }}
                                            ></div>
                                        ))}
                                    </div>

                                    <div
                                        className="self-center bg-secondary"
                                        style={{ width: "95%", height: 1.2 }}
                                    ></div>

                                    <div className="flex flex-row px-4 py-2 justify-between">
                                        {timeLabels.map((label, index) => (
                                            <p
                                                key={index}
                                                className="text-primary text-[12px] font-semibold"
                                            >
                                                {label}
                                            </p>
                                        ))}
                                    </div>

                                    <div className="absolute inset-0 flex justify-center items-center bg-tertiary bg-opacity-75 rounded-2xl">
                                        <p className="text-primary text-lg font-bold px-4 -mt-16">
                                            Not enough available data
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* {data.trafficHours ? (
                <div className="flex flex-col bg-tertiary border-2 lg:border-4 border-secondary rounded-3xl justify-between h-72">
                  <div className="flex flex-row justify-between">
                    <p className="text-primary text-lg font-bold px-4 pt-4">
                      Traffic Hours
                    </p>
                  </div>

                  <div className="flex mx-[12px] lg:mx-4 border-t-2 border-secondary -mt-10 lg:-mt-6"></div>

                  <div className="flex flex-col">
                    <div className="flex flex-row items-end justify-between pb-2 px-10">
                      {data.trafficHours.map((hour, index) => (
                        <div
                          key={index}
                          className={`bg-secondary w-3 lg:w-4 h-${hour} rounded-full`}
                        ></div>
                      ))}
                    </div>

                    <div
                      className="self-center bg-secondary"
                      style={{ width: "95%", height: 1.2 }}
                    ></div>

                    <div className="flex flex-row px-4 py-2 justify-between">
                      <p className="text-primary text-[12px] font-semibold">
                        6am
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        10am
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        2pm
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        6pm
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        10pm
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col bg-tertiary border-2 lg:border-4 border-secondary rounded-2xl justify-between h-72">
                  <div className="flex flex-row justify-between">
                    <p className="text-primary text-lg font-bold px-4 pt-4">
                      Traffic Hours
                    </p>
                  </div>

                  <div className="flex mx-[12px] lg:mx-4 border-t-2 border-secondary -mt-10 lg:-mt-6"></div>

                  <div className="flex flex-col relative">
                    <div className="flex flex-row items-end justify-between pb-2 px-10">
                      <div className="bg-secondary w-3 lg:w-4 h-8 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-16 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-32 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-8 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-16 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-32 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-8 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-16 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-32 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-8 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-16 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-32 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-8 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-16 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-32 rounded-full"></div>
                      <div className="bg-secondary w-3 lg:w-4 h-8 rounded-full"></div>
                    </div>

                    <div
                      className="self-center bg-secondary"
                      style={{ width: "95%", height: 1.2 }}
                    ></div>

                    <div className="flex flex-row px-4 py-2 justify-between">
                      <p className="text-primary text-[12px] font-semibold">
                        6am
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        10am
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        2pm
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        6pm
                      </p>
                      <p className="text-primary text-[12px] font-semibold">
                        10pm
                      </p>
                    </div>

                    <div className="absolute inset-0 flex justify-center items-center bg-tertiary bg-opacity-75 rounded-2xl">
                      <p className="text-primary text-lg font-bold px-4 -mt-16">
                        Not enough available data
                      </p>
                    </div>
                  </div>
                </div>
              )} */}
                        </div>
                    </div>

                    {/* Gym Details */}
                    <GymDetailsComponent gymData={currGymData} />

                    {/* Near You Section */}
                    <NearYou
                        heading="CHECK OUT FITNESS CENTRES NEAR YOU"
                        headingMobile="FITNESS CENTRES NEAR YOU"
                        bg="bg-tertiary"
                        headingColor="text-secondary"
                        size="-ml-8"
                        locationIconColor={themeColors.lightAccent}
                        location={currGymData.city}
                        name="GYM NAME"
                        selectedCity={currGymData.city}
                        gymName={currGymData.gymName}
                    />
                </div>
            ) : (
                <div>No gym data available.</div>
            )}
        </div>
    );
};

export default GymPage;

// Getting count of 5,4,3,2,1 stars rating
const getRatingCounts = (reviews) => {
    const counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    reviews.forEach(review => {
        const rating = review.rating;
        if (rating >= 1 && rating <= 5) {
            counts[rating]++;
        }
    });
    return counts;
};