// FormComponent.jsx
import React, { useState } from 'react';
import { PillButton } from '../helperFunctions/MarketplaceHelpers/ButtonsLibrary';

const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 bg-bg bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-tertiary rounded-lg p-6 text-center shadow-lg">
                <p className="mb-4">{message}</p>
                <button onClick={onClose} className="bg-secondary text-tertiary py-2 px-4 rounded-lg hover:bg-primary transition-all duration-500">
                    Close
                </button>
            </div>
        </div>
    );
};

const FormComponent = ({ onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        city: '',
        message: '',
        category: '' // New field for the selected category
    });

    const [selectedCategory, setSelectedCategory] = useState(''); // For tracking selected category
    const [alertMessage, setAlertMessage] = useState(''); // State to manage alert message
    const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCategoryClick = (value) => {
        setSelectedCategory(value); // Update selected category state
        setFormData({
            ...formData,
            category: value, // Update category in form data
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, email, phone, city, message, category } = formData;

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setAlertMessage("Please enter a valid email address.");
            setShowAlert(true);
            return;
        }

        // Phone number validation (exactly 10 digits)
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phone)) {
            setAlertMessage("Please enter a valid 10-digit phone number.");
            setShowAlert(true);
            return;
        }

        // Check if category is selected
        if (!category) {
            setAlertMessage("Please select a category.");
            setShowAlert(true);
            return;
        }

        // Check if all fields are filled
        if (!name || !email || !phone || !city || !message) {
            setAlertMessage("Please fill out all the fields.");
            setShowAlert(true);
            return;
        }

        // If all validations pass, proceed with form submission
        onSubmit(name, email, [phone, city, message, category]);
        onClose();
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <div id="ContactUs" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <form onSubmit={handleSubmit} className="bg-tertiary w-full h-auto lg:w-1/2 rounded-lg p-8">
                <h2 className="text-md lg:text-4xl font-semibold mb-4 text-primary">Connect with Us!</h2>
                <div className="mb-1 lg:mb-4 lg:w-full flex flex-wrap flex-col lg:flex-row items-center sm:justify-center lg:justify-center">
                    {/* Category selection buttons */}
                    <PillButton
                        label="Gyms"
                        value="Gyms"
                        onClick={() => handleCategoryClick('Gyms')}
                        selected={selectedCategory === 'Gyms'} 
                    />
                    <PillButton
                        label="Classes"
                        value="Classes"
                        onClick={() => handleCategoryClick('Classes')}
                        selected={selectedCategory === 'Classes'} 
                    />
                    <PillButton
                        label="User"
                        value="User"
                        onClick={() => handleCategoryClick('User')}
                        selected={selectedCategory === 'User'}
                    />
                    <PillButton
                        label="Collaborator"
                        value="Collaborator"
                        onClick={() => handleCategoryClick('Collaborator')}
                        selected={selectedCategory === 'Collaborator'}
                    />
                </div>
                <div className="flex flex-row gap-x-4">
                    {/* Input fields for user information */}
                    <input type="text" id="name" name="name" placeholder="✈️ Full Name" value={formData.name} required onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 rounded-2xl border-secondary placeholder-color custom-input" />
                    <input type="text" id="email" name="email" placeholder="✉️ Email Address" value={formData.email} required onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 rounded-2xl border-secondary placeholder-color custom-input" />
                </div>
                <div className="flex flex-row gap-x-4">
                    <input type="text" id="phone" name="phone" placeholder="☎️ Phone Number" value={formData.phone} required onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 rounded-2xl border-secondary placeholder-color custom-input" />
                    <input type="text" id="city" name="city" placeholder="🗺️ City" value={formData.city} required onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 rounded-2xl border-secondary placeholder-color custom-input" />
                </div>
                <div className="flex flex-row gap-x-4">
                    <textarea id="message" name="message" placeholder="Write a message" value={formData.message} required onChange={handleChange} className="h-20 lg:h-40 px-4 pt-2 rounded-xl bg-tertiary text-primary mb-4 w-full border-4 rounded-2xl border-secondary placeholder-color custom-input"></textarea>
                </div>
                <button type="button" onClick={handleSubmit} className="bg-secondary text-tertiary py-2 px-4 rounded-2xl hover:bg-primary transition-all duration-200">
                    Submit
                </button>
                <button type="button" onClick={onClose} className="ml-4 bg-secondary text-white py-2 px-4 rounded-2xl hover:bg-primary transition-all duration-200">
                    Close
                </button>
            </form>
            {/* Conditionally render the custom alert */}
            {showAlert && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
        </div>
    );
};

export default FormComponent;