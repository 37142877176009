// src/components/SkeletonLoader.js

import React from 'react';

const GymPageSkeleton = () => {
  return (
    <div className="flex flex-col px-8 lg:px-48 pt-48 bg-tertiary">
      <div className="flex flex-row h-96 bg-secondary border-4 border-secondary rounded-2xl">
        <div className="relative flex h-full lg:h-84 w-full border-2 border-secondary rounded-2xl animate-pulse">
          <div className="w-full h-full bg-gray-300 rounded-2xl"></div>
        </div>
        <div className="hidden lg:flex flex-col border-2 border-secondary rounded-2xl w-full">
          <div className="flex h-56 bg-gray-300 rounded-2xl animate-pulse"></div>
          <div className="flex flex-row w-full">
            <div className="flex h-36 w-full bg-gray-300 rounded-2xl animate-pulse"></div>
            <div className="relative flex h-36 w-full bg-gray-300 rounded-2xl animate-pulse"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-4">
        <div className="h-8 bg-gray-300 rounded w-3/4 mb-4 animate-pulse"></div>
        <div className="h-6 bg-gray-300 rounded w-1/2 mb-2 animate-pulse"></div>
        <div className="h-6 bg-gray-300 rounded w-1/3 mb-2 animate-pulse"></div>
        <div className="h-6 bg-gray-300 rounded w-1/2 animate-pulse"></div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full h-12 bg-gray-300 rounded mb-4 animate-pulse"></div>
        <div className="w-full h-12 bg-gray-300 rounded animate-pulse"></div>
      </div>
    </div>
  );
};

export default GymPageSkeleton;
