// src/components/SignInPopup.js
import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, signInWithCredential, linkWithCredential, updateProfile } from 'firebase/auth';
import { auth, db } from '../config/firebase';
import "../index.css"
import Modal from 'react-modal';
import { handlePayment } from '../helperFunctions/RazorpayHelper';
import { doc, setDoc } from '@firebase/firestore';

const SignUpPopup = ({ myData }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [credential, setCredential] = useState();
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [name, setName] = useState('');
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState("");
  const [fullPhoneNumber, setFullPhoneNumber] = useState('');

  const [countryCode, setCountryCode] = useState('+91'); // Default country code

  useEffect(() => {
    const initializeRecaptcha = async () => {
      if (!window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            },
            'expired-callback': () => {
            }
          });
          await window.recaptchaVerifier.render();
        } catch (error) {
          // console.error("Error initializing reCAPTCHA", error);
        }
      }
    };
    initializeRecaptcha();
  }, []);

  const handlePhoneNumberSubmit = async () => {
    setFullPhoneNumber(`${countryCode}${phone}`)
    const appVerifier = window.recaptchaVerifier;

    if (!window.recaptchaVerifier) {
      // console.error("reCAPTCHA verifier is not initialized");
      return;
    }

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      // console.error("Error during phone number verification", error);
    }
  };

  const handleCodeVerification = async () => {
    try {
      setCredential(PhoneAuthProvider.credential(verificationId, verificationCode));
      // await signInWithCredential(auth, credential);
      setShowEmailPasswordForm(true);
    } catch (error) {
      // console.error("Error verifying code", error);
    }
  };

  const addUserToDoc = async (user) => {
    try {
      const userRef = doc(db, 'user', user.uid)
      await setDoc(userRef, {
        name: name,
        age: age,
        displayName: name,
        gym: myData.gymName,
        email: email,
        gender: gender,
        phoneNumber: fullPhoneNumber,

      })
      return true;
    } catch (error) {
      // console.log('Error : ', error);
      return error;
    }
  }

  const handleSignUp = async () => {
    try {

      // Check if user has provided all the information
      if (email && password && confirmPassword && name && age) {

        // Check if passwords match each other
        if (password == confirmPassword) {
          // Step 1: Create the user with email and password
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;

          const response = await addUserToDoc(user);

          if (response == true) {
            await updateProfile(user, {
              displayName: name  // Use the name from the input
            });

            await linkWithCredential(user, credential);

            if (user) {
              handlePayment({
                city: myData.city,
                gymName: myData.gymName,
                amount: myData.amount,
                months: myData.months,
                user: user,
                image: myData.image
              });
            }
          }
          else {
            // alert('Error : ', response, '\nTry Again!');
          }
        }
        else {
          alert('Passwords Do Not match. Try Again!');
        }
      }


    } catch (error) {
      // console.error("Error signing up or linking phone number", error);
      alert("Error signing up or linking phone number : ", error)
    }
  };

  return (
    // <Modal
    //   isOpen={true}
    //   onRequestClose={() => console.log('Closing')}
    //   // contentLabel="Sign In or Sign up"
    //   // className="fixed inset-0 flex items-center justify-center z-50 text-tertiary"
    //   // overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75"
    // >
    <div className="signin-popup">
      {!showEmailPasswordForm ? (
        <div>
          <h2>Verify Phone Number</h2>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter phone number"
          />
          <button onClick={handlePhoneNumberSubmit} id="sign-in-button">
            Send Verification Code
          </button>
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter verification code"
          />
          <button onClick={handleCodeVerification}>Verify Code</button>
          <div id="recaptcha-container"></div>
        </div>
      ) : (
        <div>
          <h2>Sign Up</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
          />
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Name"
          />
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            placeholder="Select Your Gender"
            className="px-4 py-2 border rounded w-full"
          >
            <option value="">Select Your Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="Enter Your Age"
          />
          <button onClick={handleSignUp}>Sign Up</button>
        </div>
      )}
    </div>
    // </Modal>
  );
};

export default SignUpPopup;