/**
 * BillGeneration.js
 * 
 * Renders the PrintView of the Receipts in a modal and allows the user to generate a PDF or send the receipt details to a phone number.
 */

import React, { useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toWords } from "number-to-words";
import { getDate, getTime } from "../../basicHelper";
import Icon from "../../../assets/Icon-512.png";

// Initialize pdfMake with fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * Converts the total amount to words using the number-to-words library.
 * 
 * @param {number} amount - The total amount to convert to words.
 * @returns {string} - The total amount in words.
 */
const getTotalAmountInWords = (amount) => {
    return toWords(amount).toUpperCase();
};

/**
 * Creates the PDF document definition.
 * 
 * @param {Object} receipt  - The receipt details.
 * @param {Object} response - The Razorpay object
 * @returns {Object} - The document definition for the PDF.
 */
const createDocDefinition = (receipt, response) => {
    const totalAmount = receipt.amount / 100 || 0;
    const totalAmountInWords = getTotalAmountInWords(totalAmount);

    let netPrice = totalAmount / 1.18;
    let cgst = 0.09 * netPrice;
    let sgst = 0.09 * netPrice;
    sgst = parseFloat(sgst.toFixed(2));
    cgst = parseFloat(sgst.toFixed(2));
    netPrice = parseFloat(netPrice.toFixed(2));

    // Create content array
    const content = [];

    // Only include the image if it exists and is valid
    if (receipt.image && typeof receipt.image === 'string' && receipt.image.trim() !== '') {
        content.push({
            image: receipt.image,            // Gym logo as base64 or URL
            fit: [85, 85],                   // Adjust the size of the logo
            alignment: 'left',               // Align the logo to the left
            margin: [0, 0, 0, 10]            // Add some margin below the logo
        });
    }

    // Add the rest of the content
    content.push(
        {
            text: receipt.gymDisplayName || 'Gym Name',
            fontSize: 20,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 10]
        },
        {
            text: `${receipt.address || 'Address'}, ${receipt.cityDisplayName || 'City'}\n` +
                `Phone: ${receipt.gymContactNo || 'Phone'} | Email: ${receipt.gymEmail || 'Email'}\n` +
                `GSTIN: ${receipt.gstin || 'N/A'}`,
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 20]
        },
        {
            text: `Name: ${receipt.user.displayName || 'N/A'}\n` +
                `Phone: ${receipt.user.phoneNumber || 'N/A'}`,
            margin: [0, 0, 0, 10]
        },
        {
            text: `Transaction No: ${response.razorpay_payment_id || 'N/A'}\n` +
                `Receipt Date: ${getDate()}\n` +
                `Payment Status: Paid`,
            margin: [0, 0, 0, 10]
        },
        {
            table: {
                headerRows: 1,
                widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    [
                        { text: 'Qty', bold: true },
                        { text: 'Start Date', bold: true, alignment: 'right' },
                        { text: 'End Date', bold: true, alignment: 'right' },
                        { text: 'Net Amount', bold: true, alignment: 'right' },
                        { text: 'CGST (9%)', bold: true, alignment: 'right' },
                        { text: 'SGST (9%)', bold: true, alignment: 'right' },
                        { text: 'Total Amount', bold: true, alignment: 'right' },
                    ],
                    [
                        { text: `${receipt.months || '0'} Months`, fontSize: 8 },
                        { text: `${getDate()}`, alignment: 'right', fontSize: 8 },
                        { text: `${receipt.endDate}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${netPrice || '0'}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${cgst || '0'}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${sgst || '0'}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${totalAmount || '0'}`, alignment: 'right', fontSize: 8 },
                    ],
                ]
            },
            layout: {
                fillColor: '#f3f3f3',
                hLineColor: function (i, node) { return (i === 0 || i === node.table.body.length) ? 'black' : '#ccc'; },
                vLineColor: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 'black' : '#ccc'; },
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0.5; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0.5; },
                paddingLeft: function (i, node) { return 4; },
                paddingRight: function (i, node) { return 4; },
                paddingTop: function (i, node) { return 2; },
                paddingBottom: function (i, node) { return 2; },
            }
        },
        {
            columns: [
                {
                    width: '*',
                    text: [
                        `Payment Date and Time: ${formatDateTime(getTime())}`,
                        `\nReceipt No: ${response.razorpay_payment_id || 'N/A'}`,
                        `\nPersonal Trainer : ${receipt.personalTrainer ? receipt.personalTrainer : 'No'}`
                    ],
                    margin: [0, 0, 10, 0]
                },
                {
                    width: 'auto',
                    text: [
                        `Total: ₹${receipt.amount / 100 || '0'}\n`,
                        `Paid: ₹${receipt.amount / 100 || '0'}`
                    ],
                    alignment: 'right'
                }
            ]
        },
        {
            text: `Total Amount in Words: Rupees ${totalAmountInWords} Only`,
            alignment: 'right',
            margin: [0, 10, 0, 10]
        },
        {
            text: `${receipt.gymDisplayName || 'Gym Name'} Terms & Conditions:`,
            bold: true,
            margin: [0, 20, 0, 5]
        },
        {
            ul: [
                'Membership rates can be revised by the management.',
                'No membership is refundable.'
            ]
        }
    );

    // Return the document definition
    return {
        content,
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black'
            },
            tableBody: {
                fontSize: 10
            }
        }
    };
};


/**
 * Generates and opens the PDF.
 * 
 * @param {Object} receipt  - The receipt details.
 * @param {Object} response - The razorpay payment id.
 */
export const generatePdf = (receipt, response) => {
    try {
        const docDefinition = createDocDefinition(receipt, response);
        const generatedPDF = pdfMake.createPdf(docDefinition);

        // Instead of directly opening the PDF, provide options to open or download it
        generatedPDF.getBlob((blob) => {
            const blobURL = URL.createObjectURL(blob);

            // Open PDF in a new tab (within user gesture to avoid browser popup block)
            const newWindow = window.open(blobURL, '_blank');
            
            if (!newWindow) {
                console.error('Failed to open new window, browser may have blocked it.');
                alert('Popup blocked. Please allow popups for this site to view the PDF.');
            }
        });

        return generatedPDF;
    } catch (error) {
        console.log('Error in Generating Bill PDF:', error);
    }
};


/**
 * Formats the ISO date string to a user-friendly date and time format.
 * 
 * @param {String} isoString - The ISO date string (e.g., '2024-09-14T10:44:10.581Z').
 * @returns {String} - Formatted date and time string.
 */
const formatDateTime = (isoString) => {
    const dateObj = new Date(isoString);
    
    // Format the date as 'DD/MM/YYYY'
    const formattedDate = dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    // Format the time as 'HH:MM AM/PM'
    const formattedTime = dateObj.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    // Return the formatted date and time
    return `${formattedDate} at ${formattedTime}`;
};
