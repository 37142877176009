/**
 * NearYou.js
 * 
 * Renders the Near You component on marketplace homescreen
 */

import { useNavigate } from "react-router-dom";
import { Next } from "../../assets/WelcomeScreenImage";
import { Carousel, Placard } from "./ButtonsLibrary";
import { useEffect, useState } from "react";
import { fetchAllBlogs, fetchAllGyms } from "./MarketplaceHelper";
import icon from '../../assets/Icon.jpeg';
import data from '../../assets/data';

/**
 * Renders the Near You component on homepage
 * @param {Object} props 
 * @returns {JSX.Element} - Near You component
 */
export const NearYou = (props) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640); // Adjust breakpoint as needed (640px for "sm" screens)

    // This function helps in increasing responsiveness
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [cityGymList, setCityGymList] = useState(null);
    const [blogList, setBlogList] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        setLoading(true); // Start loading

        const cityGyms = async () => {
            
            // Near You Blogs? 
            // No Idea

            if (props.heading.includes('BLOG')) {
                const blogsList = await fetchAllBlogs();
                setBlogList(blogsList);
                console.log("PlaCard blogList ", blogList);
            } 
            
            // If Not blogs,
            // Fetch Gyms ?
            // Hmm Kwazyyy Element

            else {
                const cityGymList = await fetchAllGyms(props.selectedCity); // Fetch the gym data for the selected city
                setCityGymList(cityGymList);
            }
            setLoading(false); // Stop loading
        };

        cityGyms();
    }, [props.selectedCity, props.heading]);

    // Same for handling Navigation
    const handleNavigation = () => {

        // Only the selected city needs to pass in listing page
        const propToPass = props.selectedCity

        if (props.heading.includes('BLOG')) {
            navigate('/BlogList');
        } else {
            navigate('/Listing', { state: { propToPass } });
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[props.gymName])

    const getPlacardProps = (gymID, gymName, locality, gymDisplayName, city, cityDisplayName, content, time, image, price) => ({
        gymID               : gymID,
        gymName             : gymName,
        locality            : locality,
        hidden              : props.heading.includes('BLOG'),
        bg                  : props.bg === 'bg-secondary' ? 'bg-tertiary w-52 lg:w-1/3' : 'bg-secondary w-52 lg:w-1/3',
        textColor           : props.headingColor === 'text-secondary' ? 'text-tertiary' : 'text-secondary',
        locationIconColor   : props.locationIconColor,
        city                : city,
        cityDisplayName     : cityDisplayName,
        gymDisplayName      : gymDisplayName,
        content             : content ? content : null,
        time                : time ? time : null,
        image               : image,
        price               : price ? price : null // Add the price here
    });

     // Filter if gymName exists, else return all
    const slides = (cityGymList || []).filter(gym => !props.gymName || gym.gymName !== props.gymName).slice(0, 3).map((gym, index) => (
        <Placard
            key={index}
            {...getPlacardProps(
                gym.gymID,              // gymID
                gym.gymName,            // gym db Name
                gym.locality,           // locality
                gym.gymDisplayName,     // gym Display Name
                gym.city,               // city db name
                gym.cityDisplayName,    // city display name
                null,                   // content
                null,                   // time
                gym.image,              // image
                gym.price               // This is coming as the yearlyPrice
            )}
        />
    ));

    const blogSlides = (blogList || []).slice(0, 3).map((blog, index) => (
        <Placard key={index} {...getPlacardProps(blog.name, "by "+blog.location, blog.content, blog.time, blog.image)} />
    ));

    return (
        <div className={`${props.bg} ${props.size} flex flex-col rounded-l-3xl lg:rounded-3xl mx-4 lg:my-16 h-[400px] lg:h-1/4`}>
            <button 
                onClick={handleNavigation} 
                className="py-8 flex flex-row justify-between items-center px-8 transition-all duration-300 ease-in-out">
                <h2 
                    className={`flex text-lg lg:text-4xl font-bold ${props.headingColor} 
                    transform transition-transform duration-300 ease-in-out hover:scale-105`}>
                    {isMobile ? props.headingMobile : props.heading}
                </h2>
                <Next 
                    className="w-6 lg:w-12 h-4 lg:h-7 -mr-6 lg:-mr-0 
                    transform transition-transform duration-300 ease-in-out hover:scale-125 hover:w-16 hover:h-10" 
                />
            </button>

            <div className="w-full">
                {cityGymList && !props.heading.includes('BLOG') &&
                <>
                    <div className="hidden lg:flex flex-row justify-between">
                        {slides}
                    </div>
                </>}
                {blogList && props.heading.includes('BLOG') &&
                    <div className="hidden lg:flex flex-row justify-between">
                        {blogSlides}
                    </div>}
                <div className="lg:hidden justify-between">
                    <Carousel slides={props.heading.includes('BLOG') ? blogSlides || [] : slides || []} />
                </div>
            </div>
        </div>
    );
};



