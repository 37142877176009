import { collection, getDoc, doc, getDocs, updateDoc } from "@firebase/firestore";

import { db, dbGym } from "../../config/firebase";
import axios from "axios";
import { gymList, cityList } from "../../components/Databases";
import { removeSpacesAndConvertLowercase } from "../basicHelper";

/*
 * Fetches Top 3 Gyms in user's city
 */
export const fetchTopGyms = async () => {
    const cityWiseData = [];
    
    try {
        const citiesSnapshot = await getDocs(collection(db, cityList));

        if (citiesSnapshot.empty) {
            return cityWiseData;
        }

        for (const cityDoc of citiesSnapshot.docs) {
            const city = cityDoc.id;
            let userCount = 0;
            
            const gymsSnapshot = await getDocs(collection(db, cityList, city, gymList));

            if (gymsSnapshot.empty) {
                continue;
            }

            for (const gymDoc of gymsSnapshot.docs) {
                const gymName = gymDoc.id;

                const attendanceSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, 'Attendance'));

                if (attendanceSnapshot.empty) {
                    continue;
                }

                for (const attendanceDoc of attendanceSnapshot.docs) {
                    const date = attendanceDoc.id;
                    const usersSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, 'Attendance', date, 'Users'));
                    userCount += usersSnapshot.size;
                }
            }

            cityWiseData.push({
                city: city,
                users: userCount
            });
        }

        return cityWiseData;
    } catch (error) {
        // console.error('Error fetching city-wise data: ', error);
        return cityWiseData;
    }
};

// Fetch Top Blogs
export const fetchAllBlogs = async () => {
    const blogs = [];
    const blogSnapshot = await getDocs(collection(db, 'blogs'));
    for (const blog of blogSnapshot.docs) {
        const blogData = blog.data();
        // console.log("BLOG DATA", blogData.image)
        blogs.push({
            gymDisplayName    : blogData.title,
            content : blogData.content,
            image   : blogData.image,
            locality: blogData.author,
            time    : blogData.timestamp
        });
    }
    return blogs;
};

const filteredGyms = [
    '21fitness',
    'notlisted',
    'arnavgym',
    'setfitness'
]
// Fetch all Gyms
export const fetchAllGyms = async (city) => {
    const gymListData = [];
    const cityGymSnapshot = await getDocs(collection(db, cityList, city.toLowerCase(), gymList));
    for (const gym of cityGymSnapshot.docs) {
        const gymData = gym.data();
        if (filteredGyms.includes(gymData.gymName)) 
            continue;
        
        // Else Push the data to gymList
        gymListData.push({
            city            : removeSpacesAndConvertLowercase(city), 
            gymID           : gymData.gymID,
            gymName         : gymData.name, 
            name            : gymData.gymDisplayName,
            gymDisplayName  : gymData.gymDisplayName,
            cityDisplayName : gymData.cityDisplayName,
            rating          : gymData.rating,
            NoOfReview      : gymData.NoOfReview,
            locality        : gymData.locality,
            image           : gymData.Portfolio==='<url>'?['']:[gymData.Portfolio[0]],
            address         : `${gymData.address}, ${gymData.cityDisplayName} ,${gymData.pinCode}`,
            price           : gymData.yearlyPrice
        });
    }
    return gymListData;
};

// Fetch Gym Data of selected gym

// Get User City
export async function getLocation() {
    // it will return the following attributes:
    // country, countryCode, regionName, city, lat, lon, zip and timezone
    const res = await axios.get("http://ip-api.com/json");
    return res.data.city
}

/**
 * Helps in fetching all the gym data
 * Needed for Product Portfolio Page + Buying the membership
 * @param {String} city
 * @param {String} gymName
 */
export const fetchGymData = async (city, gymName) => {
    try {
        // Create a reference to the gym document
        const gymRef = doc(db, cityList, city, gymList, gymName);
        const gymDoc = await getDoc(gymRef);

        if (gymDoc.exists()) {
            return gymDoc.data();
        } else {
            // console.log('No Gym Found');
            throw new Error('404: No Gym Found');
        }
    } catch (error) {
        // console.error('Error fetching gym data:', error.message);
        throw error;
    }
};

export const fetchCities = async () => {
    const cities = [];
    const citySnapshot = await getDocs(collection(db, cityList));
    for (const city of citySnapshot.docs) {
        // Filtering Test City for main website
        if (city.id != 'testcity')
            cities.push(city.id);
    }
    return cities;
};


/**
 * Updates all gyms in the "Cities/delhi/Gyms" collection with exclusive price fields.
 * Skips the gym with the gymName 'gypsy'.
 */
export const updateExclusivePricesForGyms = async () => {
    try {
        // Get all gyms from the "Cities/delhi/Gyms" collection
        const gymsCollectionRef = collection(dbGym, "Gyms");
        const gymsSnapshot = await getDocs(gymsCollectionRef);

        // Iterate through each gym document
        gymsSnapshot.forEach(async (gymDoc) => {
            const gymData = gymDoc.data();
            const { gymName, dailyPrice, monthlyPrice, quarterlyPrice, halfYearlyPrice, yearlyPrice } = gymData;

            // Create new exclusive price fields with numeric values
            const exclusivePrices = {
                exclusiveDailyPrice: Number(dailyPrice) || 0,
                exclusiveMonthlyPrice: Number(monthlyPrice) || 0,
                exclusiveQuarterlyPrice: Number(quarterlyPrice) || 0,
                exclusiveHalfYearlyPrice: Number(halfYearlyPrice) || 0,
                exclusiveYearlyPrice: Number(yearlyPrice) || 0,
            };

            // Update the gym document with the new fields
            const gymRef = doc(dbGym, "Gyms", gymDoc.id);
            await updateDoc(gymRef, exclusivePrices);

            // console.log(`Updated gym: ${gymName} with exclusive prices.`);
        });
    } catch (error) {
        // console.error("Error updating exclusive prices for gyms:", error);
    }
};