import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet, useLocation } from 'react-router-dom';

// Import Screens
import HomePage from './Screens/AppDescription/HomePage';
import Marketplace from './Screens/marketplace/Marketplace';
import Listing from './Screens/marketplace/Listing';
import ProductPortfolio from './Screens/marketplace/ProductPortfolio';
import TCPage from './Screens/AppDescription/TCPage';
import PPPage from './Screens/AppDescription/PPPage';
import GymForm from './Screens/GymForm';
import GymCred from './Screens/GymCred';
import BlogPost from './Screens/marketplace/BlogPost';
import BlogList from './Screens/marketplace/BlogList';
import WriteBlog from './Screens/marketplace/WriteBlog';
import Careers from './Screens/AppDescription/careers';

// Import Components
import NavBar_Marketplace from "./components/NavBar_Marketplace";
import NavBar_Home from "./components/NavBar"; // Import the new NavBar component for HomePage
import BottomBar from "./components/BottomBar";
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";
import './index.css'; // Import the global stylesheet
import { getLocation } from './helperFunctions/MarketplaceHelpers/MarketplaceHelper';
import NavBar_Marketplace_helper from './helperFunctions/Navbar_helpers/Navbar_MarketPlace_helper';
import Navbar_App_helper from './helperFunctions/Navbar_helpers/Navbar_App_helper';
import GymPage from './Screens/GymPage';

// Layout for Marketplace-related pages
const MarketplaceLayout = ({ selectedCity, onLocationChange }) => (
    <>
        <NavBar_Marketplace_helper location={selectedCity} onLocationChange={onLocationChange} />
        <Outlet />
    </>
);

// Layout for Train Rex App pages
const TrainRexLayout = () => (
    <>
        <Navbar_App_helper />
        <Outlet />
    </>
);

function App() {
    const location = useLocation();

    const [userCity, setUserCity] = useState(null);

    useEffect(() => {
        // Log an event when the user enters the app
        const currentTime = new Date().toISOString();
        const userAgent = navigator.userAgent;
        logEvent(analytics, `${userAgent}, ${currentTime}`);
    }, []);

    useEffect(() => {
        setUserCity(getLocation());
    }, []);

    const [selectedCity, setSelectedCity] = useState('delhi'); // Initial location

    const handleLocationChange = (city) => {
        setSelectedCity(city);
    };

    return (
        <div className="App">
            <Routes>
                <Route element={<MarketplaceLayout selectedCity={selectedCity} onLocationChange={handleLocationChange} />}>
                    <Route path="/" element={<Marketplace selectedCity={selectedCity}/>} />
                    <Route path="/Listing" element={<Listing selectedCity={selectedCity}/>} />
                    <Route path="/ProductPortfolio" element={<ProductPortfolio selectedCity={selectedCity}/>} />
                    <Route path="/BlogList" element={<BlogList selectedCity={selectedCity}/>} />
                    <Route path="/BlogPost" element={<BlogPost selectedCity={selectedCity}/>} />
                    <Route path="/WriteBlog" element={<WriteBlog />} />
                    <Route path="/BusinessSignUp" element={<GymForm />} />
                    <Route path="/Credentials" element={<GymCred />} />
                    <Route path="/gym/:gymSlug" element={<GymPage />} />
                </Route>

                {/* Train Rex App Navbar */}
                <Route element={<TrainRexLayout />}>
                    <Route path="/AppDescription" element={<HomePage />} />
                    <Route path="/AppDescription/Terms&Conditions" element={<TCPage />} />
                    <Route path="/AppDescription/PrivacyPolicy" element={<PPPage />} />
                    <Route path="/AppDescription/Careers" element={<Careers />} />
                    {/* Add more routes here for the Train Rex App */}
                </Route>
            </Routes>
            {/* Conditionally render the BottomBar */}
            <BottomBar />
        </div>
    );
}

export default App;
