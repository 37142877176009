import logoSVG from '../../../assets/Website_content/TR_Website_content-04.svg'

{/* Home Page- First block */}
const Tagline = ({ gymData }) => {    
    return (
        <div className="flex flex-row justify-center items-center bg-secondary py-4 gap-x-4">  
            {/* <img src={logoSVG} alt="Logo" className="h-3 lg:h-4"/> */}
            <h2 className="flex text-[15px] lg:text-2xl font-bold text-tertiary">ONE STOP FITNESS SOLUTION</h2>
            {/* <img src={logoSVG} alt="Logo" className="h-3 lg:h-4"/> */}
        </div>
    );
  };
  
  export default Tagline;