import backgroundImage from '../../../assets/Website_content/TR_Image_desktop.png';
import logoSVG from '../../../assets/Website_content/TR_Website_content-04.svg'

{/* Home Page- First block */}
const PictureAndSearchFiltering = ({ gymData }) => {    
    return (
        <div id='about' className="bg-tertiary flex flex-col justify-between" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: 600 }}>
            {/* Left Column with Form */}
            <div className="lg:w-1/2 lg:pr-8 text-white flex flex-col items-center">
                {/* Form */}
                <form className="p-8 rounded-lg pt-44">
                    <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-tertiary">EXPLORE GYMS/ CLASSES</h2>
                    <div className="mb-4 w-3/4 flex w-1/2 rounded-2xl">
                        {/* <label htmlFor="searchInput1" className="block text-gray-700">Input 1</label> */}
                        <input type="text" id="searchInput1" name="searchInput1" placeholder="Select Location" className="w-full h-10 px-4 rounded-xl bg-tertiary text-primary focus:border-4 focus:border-secondary placeholder-color custom-input"/>
                    </div>
                    <div className="mb-4 w-3/4 flex w-1/2 rounded-2xl">
                        {/* <label htmlFor="searchInput1" className="block text-gray-700">Input 1</label> */}
                        <input type="text" id="searchInput2" name="searchInput2" placeholder="Select Fitness Type" className="w-full h-10 px-4 rounded-xl bg-tertiary text-primary focus:border-4 focus:border-secondary placeholder-color custom-input"/>
                    </div>
                    <button type="submit" className="flex w-fit bg-secondary text-tertiary font-semibold py-2 px-6 rounded-full hover:bg-tertiary hover:border-4 hover:border-secondary hover:text-primary">
                        Search
                    </button>
                </form>
            </div>
            <img src={logoSVG} alt="Logo" className="h-7 md:h-8 lg:h-9 pb-3 mx-auto "/>
        </div>
    );
  };
  
  export default PictureAndSearchFiltering;