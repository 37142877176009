import { Popup } from 'react-leaflet';
import { themeColors } from '../../../theme';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

/**
 * Helper function to generate the star rating display.
 * It calculates the number of full stars, half stars, and empty stars based on the given rating.
 * @param {number} rating - The gym's rating, a number between 0 and 5, possibly including halves (e.g., 4.5).
 * @returns JSX Elements rendering the star icons based on the rating.
 */
const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Number of full stars
    const halfStars = rating % 1 !== 0; // If there's a half star
    const emptyStars = 5 - Math.ceil(rating); // Number of empty stars to fill up to 5

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {Array(fullStars).fill().map((_, index) => (
                <FaStar key={index} style={{ color: themeColors.primary, fontSize: '1.5em' }} /> 
            ))}
            {halfStars && <FaStarHalfAlt style={{ color: themeColors.primary, fontSize: '1.5em' }} />} {/* Increased size */}
            {Array(emptyStars).fill().map((_, index) => (
                <FaRegStar key={index} style={{ color: '#FFD700', fontSize: '1.5em' }} /> 
            ))}
        </div>
    );
};

/**
 * GymPopup component to display a popup with gym information, including the name, address, and rating.
 * The component uses react-leaflet's Popup component to show the gym's location details.
 * @param {Object} loc - The location object containing name, address, and rating of the gym.
 * @returns JSX Element rendering a styled Popup with gym details.
 */
const GymPopup = ({ loc }) => {
    
    return (
        <Popup>
            <div
                style={{
                    backgroundColor: themeColors.secondary,
                    padding: '35px',
                    borderRadius: '15px',
                    // boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
                    maxWidth: '350px',
                    color: themeColors.secondary,
                    fontFamily: 'Raleway',
                    textAlign: 'center',
                    border: `2px solid ${themeColors.secondary}`, // Prevents white border
                    margin: '-30px'
                }}
                
            >
                {/* Gym Name as a clickable link navigating to "/{loc.city}-{loc.name}" */}
                <Link
                    to={`/gym/${loc.city}-${loc.gymName}`} // Navigating to the dynamic route
                    style={{
                        fontWeight: 'bold',
                        fontSize: '1.7em',
                        color: themeColors.primary,
                        textDecoration: 'none',
                        display: 'block',
                        marginBottom: '15px',
                        transition: 'color 0.3s ease',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = themeColors.tertiary)} // Hover effect
                    onMouseLeave={(e) => (e.target.style.color = themeColors.primary)} // Hover out effect
                >
                    {loc.name}
                </Link>

                {/* Gym Address */}
                <p
                    style={{
                        fontSize: '1.2em',
                        color: themeColors.white,
                        margin: '5px 0',
                    }}
                >
                    {loc.address}
                </p>

                {/* Gym Rating rendered as stars */}
                <div
                    style={{
                        fontSize: '1.1em',
                        color: '#27AE60',
                        marginTop: '10px',
                        backgroundColor: themeColors.tertiary,
                        padding: '10px',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                    }}
                >
                    {renderStars(loc.rating)}
                </div>
            </div>
        </Popup>
    );
};


export default GymPopup;
