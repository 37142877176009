import { handlePaymentFailure, paymentSuccessHandler } from "./MembershipHelper";
import { getAmount, getCurrency } from "./PaymentHelpers";
import { themeColors } from "../theme";
import icon from "../assets/Icon.jpeg"

/**
 * Generates a razorpay link
 * @returns Razorpay window script
 */
const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
};

/**
 * Handles Main Razorpay payment method
 * @param {String} city
 * @param {String} gymName
 * @param {int} months
 * @param {String} userID
 * @param {int} amount
 * @param {dict} user 
 * @returns 
 */
export const handlePayment = async (myData) => {
    const res = await loadRazorpayScript();

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
    }

    const options = {
        key: 'rzp_live_PjuEiFCgxSVySz',        // Live Mode
        // key: 'rzp_test_xWMw9b7Eg3ntwK',     // Test Mode
        amount: myData.amount,    // Amount in currency subunits (50000 refers to ₹500.00)
        currency: getCurrency(),
        name: 'Train Rex',
        description: 'Test Transaction',
        image: icon,
        // image: 'https://drive.google.com/file/d/13W_TC_n__InrjM-rn71B4vcNJ4D1Og5b/view?usp=sharing',
        handler: function (response) {
            // If payment is successful, add data to firestore
            paymentSuccessHandler(myData, response);        // Imported from MembershipHelper.js
        },
        prefill: {
            name: myData.user.displayName,
            email: myData.user.email,
            contact: myData.user.phoneNumber
        },
        notes: {
            userID  :   myData.user.uid
        },
        theme: {
            color: themeColors.fg
        },
        modal: {
            ondismiss: function() {
                // User closed the Razorpay modal without completing the payment
                // console.log('Payment failed or was cancelled by user');
                handlePaymentFailure();
            }
        }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};