import React, { useState, useEffect, useRef } from "react";
import logoSVG from "../../assets/Website_content/TR_Website_content-02.svg";
import AppButton from "../../components/AppButton";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
import SearchBar from "../MarketplaceHelpers/SearchBar";
import useAuthStatus from "../../hooks/clientAuth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase";
import SignInPopup from "../ModalHelpers/SignInModal";
import { UserLoginModal } from "../ModalHelpers/UserCreds";
import { getUserCity } from "../LocationHelpers";
// import CustomCss from '../../CustomCss.css'

function NavBar_Marketplace({ cityLocation, onLocationChange }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const profileMenuRef = useRef(null);
    const { isUserPresent, user, loading } = useAuthStatus();
    const [showSignIn, setShowSignIn] = useState(false);
    const [userCity, setUserCity] = useState(''); // Change to state

    useEffect(() => {
        async function fetchUserCity() {
            try {
                const city = await getUserCity();
                setUserCity(city); // Update state here
            } catch (error) {
                console.error("Error fetching city:", error);
            }
        }

        fetchUserCity();
    }, []);
      
      
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    };

    const logMenuItemClick = (itemName) => {
        logEvent(analytics, `menuClick, ${itemName}`);
    };

    const handleLogout = async () => {
        await auth.signOut();
        console.log("User logged out");
        setProfileMenuOpen(false);
    };

    // Handle click outside of the profile menu to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileMenuRef.current &&
                !profileMenuRef.current.contains(event.target)
            ) {
                setProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const isGuest = localStorage.getItem("usertype") === "guest";
        console.log("isGuest", isGuest);

        if (!isGuest) {
            const firstTimeout = setTimeout(() => {
                setShowSignIn(true);
            }, 3000);

            const interval = setInterval(() => {
                setShowSignIn(true);
            }, 40000);

            return () => {
                clearTimeout(firstTimeout);
                clearInterval(interval);
            };
        }
    }, []);

    return (
        <div className="bg-tertiary fixed top-0 left-0 right-0 z-50 shadow-xl">
            <div className="bg-tertiary py-4 px-16 flex justify-between items-center lg:gap-x-4">
                <div className="block lg:hidden" style={{ marginLeft: -40 }}>
                    <button
                        onClick={toggleMenu}
                        className="text-primary focus:outline-none"
                    >
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                            />
                        </svg>
                    </button>
                </div>

                <a href="/" className="w-2/3 md:w-1/3 flex items-center mb-2 lg:mb-0">
                    <img src={logoSVG} alt="Logo" className="h-8" />
                </a>

                <div className="hidden md:inline-flex w-full lg:w-2/3"> {/* Added this div on top of search bar to control the css */}
                    <SearchBar
                        location={cityLocation}
                        onLocationChange={onLocationChange}
                        className="hidden bg-red lg:inline-flex"
                    />
                </div>

                {console.log("isUserPresent", user)}

                {isUserPresent ? (
                    <div
                        className="relative flex items-center gap-2 cursor-pointer"
                        ref={profileMenuRef}
                    >
                        <AccountCircleIcon onClick={toggleProfileMenu} style={{ color: 'var(--secondary)' }} />
                        <div onClick={toggleProfileMenu} className="flex gap-1 text-lg text-primary font-bold">
                            {user?.displayName?.replace(" ", "\u00A0") || "User"}
                        </div>
                        {profileMenuOpen && (
                            <div className="absolute right-[-50px] top-8 mt-2 w-48 border-secondary bg-white border-[1px] rounded-md hover:rounded-md shadow-lg z-50">
                                <button
                                    onClick={handleLogout}
                                    className="block  hover:bg-red px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                >
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <button
                            onClick={() => setShowSignIn(!showSignIn)}
                            className="bg-primary text-white hover:bg-secondary py-2 px-4 rounded-full -mr-12 md:mr-0"
                        >
                            Sign<span className="ml-1">In/Up</span>
                        </button>
                    </div>
                )}
            </div>

            
            <UserLoginModal
                modalIsOpen={showSignIn}
                setModalIsOpen={setShowSignIn}
                myData={{ city: userCity }}
            />
            

            <div className="flex md:hidden pb-4 justify-center">
                <SearchBar
                    location={cityLocation}
                    onLocationChange={onLocationChange}
                    className="flex md:hidden"
                />
            </div>

            <nav
                className={`w-1/3 h-full lg:flex py-0 px-0 lg:w-auto font-bold ${menuOpen
                    ? "w-auto flex fixed top-24 left-0 right-auto bottom-auto bg-tertiary z-50 rounded-b-2xl border-x-4 border-b-4 border-secondary"
                    : "hidden"
                    }`}
            >
                <ul className="md:flex flex-col lg:flex-row text-sm gap-4 bg-secondary w-full lg:justify-center justify-start">
                    <li>
                        <a
                            href="/Listing"
                            className="block py-2 px-4 rounded-md text-left lg:text-center text-lg text-tertiary"
                            onClick={() => logMenuItemClick("Gyms/Classes")}
                        >
                            Gyms/Classes
                        </a>
                    </li>
                    <li>
                        <a
                            href="/AppDescription"
                            className="block py-2 px-4 rounded-md text-left lg:text-center text-lg text-tertiary"
                            onClick={() => logMenuItemClick("Train Rex App")}
                        >
                            Train Rex App
                        </a>
                    </li>
                    <li>
                        <a
                            href="/BlogList"
                            className="block py-2 px-4 rounded-md text-left lg:text-center text-lg text-tertiary"
                            onClick={() => logMenuItemClick("Our Blog")}
                        >
                            Our Blogs
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default NavBar_Marketplace;
