import React, { useState, useRef, useEffect } from 'react';
import { themeColors } from '../../theme';
import { LocationIcon, SearchIcon } from '../../assets/WelcomeScreenImage';
import SearchDropDown from '../Navbar_helpers/SearchDropDown';
import { fetchCities, fetchAllGyms } from './MarketplaceHelper';

const SearchBar = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('Delhi');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchDropDown, setSearchDropDown] = useState(false);
  const searchBarRef = useRef(null);
  const [allCities, setAllCities] = useState([]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
    props.onLocationChange(location);
    setDropdownOpen(false);
  };

  // Close search dropdown on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setSearchDropDown(false);
      }
    };

    const fetchCity = async () => {
      const cities = await fetchCities();
     setAllCities(cities);
    }

    fetchCity();

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={searchBarRef}
      className={`relative flex flex-row items-center space-x-2 w-full ${props.classname} px-12`}
    >
      <div
        onClick={toggleDropdown}
        className="flex flex-row justify-center bg-secondary py-1 rounded-full text-tertiary w-2/5 lg:w-fit px-2 lg:px-3 py-1 lg:py-2 cursor-pointer"
      >
        <LocationIcon className="w-6 lg:w-8 h-4 lg:h-5 mt-1 lg:mt-0" color={themeColors.lightAccent} />
        <span className="inline mr-1 font-semibold mr-3">{selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1)}</span>
      </div>

      {dropdownOpen && (
        <div className="absolute top-12 left-5 bg-white border border-secondary rounded-md shadow-lg w-1/4">
          <ul>
            {allCities.map((location) => (
              <li
                key={location}
                className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleLocationChange(location)}
              >
                {location.charAt(0).toUpperCase() + location.slice(1)}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="flex w-full py-1 px-2 order-last rounded-full bg-tertiary border-2 rounded-2xl border-secondary relative">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onClick={() => setSearchDropDown(true)}
          placeholder="  Search"
          autoFocus={false}
          className="flex-grow bg-tertiary w-full placeholder-color custom-input"
        />
        <button type="submit" className="flex text-primary">
          <SearchIcon className="w-6 h-4 mt-1" />
        </button>
        {searchDropDown && <SearchDropDown setSearchQuery={setSearchQuery} searchQuery={searchQuery} setSearchDropDown={setSearchDropDown} cityLocation={selectedLocation}/>}
      </div>
    </div>
  );
};

export default SearchBar;
