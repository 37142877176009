import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet"; // Import Leaflet library
import "leaflet/dist/leaflet.css";
import MapLocationMark from '../../../assets/MapLocationMark.png'
import { fetchAllGyms } from "../MarketplaceHelper";
import { getMapLocations } from "./MapHelper";
import GymPopup from "./MapPopup";

// Create a custom icon
const customIcon = L.icon({
	iconUrl: MapLocationMark, // Path to your custom marker image
	iconSize: [40, 40], // Size of the icon
	iconAnchor: [16, 32], // Anchor point of the icon [x, y]
	popupAnchor: [0, -32], // Popup position relative to the icon [x, y]
});

/**
 * 
 * @param {String} selectedCity
 * @returns 
 */
const Map = ({ selectedCity }) => {
	const [activeCategory, setActiveCategory] = useState("gyms");
	const [mapLocations, setMapLocations] = useState([]);
	const [loading, setLoading] = useState(true); // Add a loading state

	useEffect(() => {
		const fetchGymsAndLocations = async () => {
		try {
			const gymData = await fetchAllGyms(selectedCity);
			const mapData = await getMapLocations(gymData); // Pass gymData instead of gyms
			setMapLocations(mapData);
		} catch (error) {
			console.error("Error fetching gym data or locations:", error);
		} finally {
			setLoading(false); // Stop loading
		}
		};

		fetchGymsAndLocations();
	}, [selectedCity]);


	if (loading) {
		return <div>Loading map...</div>; // Display a loading state if data is still being fetched
	}

	return (
		<div>
		<MapContainer
			className="h-[64vh] w-full z-0"
			center={[28.651181049999998, 77.29717993562505]} // Default center
			zoom={12}
		>
			<TileLayer
			url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			/>
			{mapLocations.map((loc, index) => (
			<Marker key={index} position={[loc.lat, loc.lng]} icon={customIcon}>
				<GymPopup loc={loc} />
			</Marker>
			))}
		</MapContainer>

		<div className="flex flex-row flex-wrap justify-center md:justify-center gap-2 md:gap-5 items-center button-group bg-tertiary my-3 mb-4 md:my-8 md:mb-8 px-2">
			<button
			className={`flex min-w-36 md:min-w-52 justify-center py-1 md:py-3 px-2 md:px-4 rounded-lg border-2 md:border-4 text-secondary font-semibold border-secondary text-xs md:text-lg ${activeCategory === "gyms" ? "bg-secondary text-tertiary" : "bg-darkTertiary"}`}
			onClick={() => setActiveCategory("gyms")}
			>
			Gyms/Fitness Centers
			</button>
			<button
			className={`flex min-w-36 md:min-w-52 justify-center py-1 md:py-3 px-2 md:px-4 rounded-lg border-2 md:border-4 text-secondary font-semibold border-secondary text-xs md:text-lg ${activeCategory === "classes" ? "bg-secondary text-tertiary" : "bg-darkTertiary"}`}
			onClick={() => setActiveCategory("classes")}
			>
			Fitness Classes
			</button>
			<button
			className={`flex min-w-36 md:min-w-52 justify-center py-1 md:py-3 px-2 md:px-4 rounded-lg border-2 md:border-4 text-secondary font-semibold border-secondary text-xs md:text-lg ${activeCategory === "sports" ? "bg-secondary text-tertiary" : "bg-darkTertiary"}`}
			onClick={() => setActiveCategory("sports")}
			>
			Sports
			</button>
		</div>
		</div>
	);
};

export default Map;