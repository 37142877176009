import { storeUserData } from "./userDatabaseHelper";
import { storeGymData } from "./gymDatabaseHelper";
import { db } from "../config/firebase";
import { cityList, gymList } from "../components/Databases";
import { collection, doc, getDocs, increment, limit, orderBy, query, updateDoc, where } from "@firebase/firestore";
import { getCurrentMonthAndYear } from "./basicHelper";
import { generatePdf } from "./MarketplaceHelpers/PaymentHelpers/BillGeneration";

/**
 * updates boughtMembership field in LeadInfo user's latest document
 * @param {Object} myData - user data including amount for membership, city, gym contact number, gym name, image and number of months of membership
 */
const updateBoughtMembership = async (myData) => {
    try {
        // Reference to the collection containing user leads
        const leadsRef = collection(db, cityList, myData.city, gymList, myData.gymName, 'Leads', getCurrentMonthAndYear(), 'LeadInfo');
        const convertedLeadsRef = doc(db, cityList, myData.city, gymList, myData.gymName, 'Leads', getCurrentMonthAndYear());


        // Query to find the user's document by email, sorted by the latest timestamp, and limit to 1
        // To configure the latest document of the user
        // BoughtMembership : True
        const leadsQuery = query(
            leadsRef,
            where('email', '==', myData.user.email),
            orderBy('timestamp', 'desc'),
            limit(1)
        );

        // Execute the query to get the document
        const querySnapshot = await getDocs(leadsQuery);

        if (!querySnapshot.empty) {
            // Get the first (latest) document from the query result
            const userDoc = querySnapshot.docs[0];

            const userData = userDoc.data()

            // Update the boughtMembership field in the document
            await updateDoc(userDoc.ref, {
                boughtMembership: true
            });

            // If boughtMembership was already true then, 
            // Do not increment
            if (!userData.boughtMembership) {
                // If not, 
                // Increment the 'converted' field in the convertedLeads document
                await updateDoc(convertedLeadsRef, {
                    converted: increment(1) // Increments the 'converted' field by 1
                });
            }

        } else {
            // console.error('No document found for the user with email:', myData.user.email);
        }
    } catch (error) {
        // console.error('Error updating boughtMembership field:', error);
        throw new Error('Could not update membership status');
    }
}

/**
 * Stores data in firestore
 * Is called when a payment is successfully made
 * @param   {dict} myData - userID, city, gymName, amount, months
 * @param   {dict} response - Razorpay Receipt
 * @returns true; if save successful, false; otherwise
 */
export const paymentSuccessHandler = async (myData, response) => {
    // store data in 'user' database
    const userResponseData = await storeUserData(myData, response);

    const userBool = userResponseData.status;

    // console.log('User Database Status : ', userBool);

    // store data in gym's database
    const gymBool = await storeGymData(myData, response);

    // console.log('Gym Database Status : ', gymBool);

    // Update the user's membership status after both user and gym data have been successfully stored
    if (userBool && gymBool) {
        try {
            await updateBoughtMembership(myData); // Call function to update membership status in the database
            // console.log('Membership updated successfully.');
            await generatePdf({...myData, endDate : userResponseData.endDate}, response);
            alert(`Payment Successful! \nw/ Payment ID: ${response.razorpay_payment_id}`);
        } catch (error) {
            // console.error('Failed to update membership status:', error);
            alert('Membership status update failed. Please contact support.');
        }
    } else {
        // console.log('Failure!');
        alert('There was an issue completing your membership. Please try again.');
    }
};


/**
 * Function to handle payment failure
 */
export const handlePaymentFailure = () => {
    // Implement your logic for handling payment failures here
    // For example, you could log the failure, alert the user, or retry the payment
    alert('Payment failed or was cancelled. Please try again.');
};
