// src/components/RichTextEditor.js
import React, { useRef, useState, useCallback, useMemo } from 'react';
import QuillEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from '../styles.module.css';

const RichTextEditor = ({ setContent }) => {
  const [value, setValue] = useState('');
  const quill = useRef();

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageUrl = reader.result;
        const quillEditor = quill.current.getEditor();
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, 'image', imageUrl, 'user');
      };

      reader.readAsDataURL(file);
    };
  }, []);

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'blockquote'],
        [{ color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
    clipboard: {
      matchVisual: true,
    },
  }), [imageHandler]);

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image', 'color', 'clean',
  ];

  return (
    <div className={styles.wrapper}>
      <QuillEditor
        ref={quill}
        className={styles.editor}
        theme="snow"
        value={value}
        formats={formats}
        modules={modules}
        onChange={(value) => {
          setValue(value);
          setContent(value);
        }}
      />
    </div>
  );
};

export default RichTextEditor;
