import iphone_home from "../../../assets/Website_content/iphone_home.png";
import iphone_appstore from "../../../assets/Website_content/iphone_appstore.png";
import { AppStore, PlayStore } from "../../../assets/WelcomeScreenImage";

export const GetTheApp = () => {
    return (
        <div className="flex flex-col md:flex-row p-6 min-h-[80vh] md:gap-20 lg:gap-52 lg:justify-center bg-darkTertiary lg:py-20">
            {/* the image */}
            <div className="flex justify-center items-center scale-75 md:scale-100 lg:scale-150">
                <img src={iphone_appstore} alt="iphone_appstore" className="h-96 w-48 scale-90 z-0 " />
                <img src={iphone_home} alt="iphone_home" className="-ml-16 h-96 w-48 z-10" />    
            </div>


            {/* the texts */}
            <div className="flex justify-center items-center flex-col">
                {/* heading */}
                <div className="font-bold text-3xl lg:text-6xl text-secondary">GET THE TRAIN REX APP!</div>
                {/* slogan */}
                <div className="font-semibold text-lg lg:text-3xl text-secondary mt-5">MATCH. TRACK. CONSULT</div>
                {/* description */}
                <div className="font-semibold text-sm lg:text-xl text-secondary mt-7">Your pocket friendly personal trainer,<br/>waiting to find you your people, track your progress<br/>and provide personal guidance,<br/>all based on your schedule</div>
                {/* app buttons */}
                <div className='flex flex-row justify-between lg:gap-x-6 mt-7'>
                    <a href="https://apps.apple.com/gb/app/train-rex/id6476014470" target="_blank" rel="noopener noreferrer" ><AppStore className="w-40 h-10 lg:h-20 cursor-pointer"/></a>
                    <a href="https://play.google.com/store/apps/details?id=com.arnav1224.gym_bro" target="_blank" rel="noopener noreferrer" ><PlayStore className="w-40 h-10 lg:h-20 cursor-pointer"/></a>
                </div>
            </div>
        </div>
    )
}