/**
 * Gets the equipment, classes, and facility lists of the gym
 * @param {Object} gymData - The gym data containing equipmentList, facilities, and classes
 * @return {Object} - An object with filtered lists of equipment, facilities, and classes that are available (i.e., set to true)
 */
export const getGymMachineryList = (gymData) => {
    // Destructure the necessary lists from gymData
    const { equipmentList = {}, facilities = {}, classes = {} } = gymData;
  
    // Filter the keys where the value is true for each list
    const availableEquipment = Object.keys(equipmentList).filter(key => equipmentList[key] === true);
    const availableFacilities = Object.keys(facilities).filter(key => facilities[key] === true);
    const availableClasses = Object.keys(classes).filter(key => classes[key] === true);
  
    // Return an object containing the filtered lists
    return {
      availableEquipment,
      availableFacilities,
      availableClasses,
    };
  };
  