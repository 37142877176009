// import icon from '../../assets/Icon.jpeg';
// import { Carousel, PillButton, Placard } from '../../helperFunctions/MarketplaceHelpers/ButtonsLibrary';
// import logoSVG from '../../assets/Website_content/TR_Website_content-04.svg'
// import { AppStore, PlayStore } from '../../assets/WelcomeScreenImage';
// import backgroundImage from '../../assets/Website_content/TR_Image_desktop.png'
// import AppInfo from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/AppInfo';

import React from 'react';
import '../../App.css';
import { NearYou } from '../../helperFunctions/MarketplaceHelpers/NearYou';
import { themeColors } from '../../theme';
import PictureAndSearchFiltering from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/PictureAndSearchFiltering';
import Tagline from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Tagline';
import ContactUs from '../../helperFunctions/MarketplaceHelpers/ContactUs';
import { Blogposts } from '../../helperFunctions/MarketplaceHelpers/Blogposts';
import { GetTheApp } from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/GetTheApp';
import { OnlineClasses, PopularActivities } from '../../helperFunctions/MarketplaceHelpers/OnlineClasses';
import Map from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Map';
import { removeSpacesAndConvertLowercase } from '../../helperFunctions/basicHelper';

/**
 * This is the main page of trainrexofficial.com
 * Main Marketplace Home Page
 * @param {String} selectedCity -   The city selected bythe user
 * @returns {JSX.Element}       -   Renders the marketplace homePage
 */
function Marketplace({ selectedCity }) {

    return (
        <div className="bg-tertiary w-full pt-16 lg:pt-28">
            {/* Main cover slideshow */}

            {/* Slideshow */}

            {/* <img src={coverImages[0]} alt="Cover" className="object-cover w-screen h-20"/> */}

            <PictureAndSearchFiltering />
            <Tagline />

            {/* fitenss centers */}
            <NearYou
                heading='CHECK OUT FITNESS CENTRES NEAR YOU'
                headingMobile='FITNESS CENTRES NEAR YOU'
                bg='bg-tertiary'
                headingColor='text-secondary'
                size='mx-auto lg:mx-40'
                locationIconColor={themeColors.lightAccent}
                selectedCity={removeSpacesAndConvertLowercase(selectedCity)}
            />

            {/* Map */}
            <Map selectedCity={selectedCity} />


            {/* Get the TrainRex App */}
            <GetTheApp />

            {/* Poplular Activites */}
            {/* <OnlineClasses
                heading='EXPLORE ONLINE CLASSES'
                headingMobile='EXPLORE ONLINE CLASSES'
                bg='bg-tertiary'
                headingColor='text-secondary'
                size='mx-auto lg:mx-40'
                locationIconColor={themeColors.lightAccent}
                selectedCity={removeSpacesAndConvertLowercase(selectedCity)} 
            /> */}


            {/* Blog List */}
            <Blogposts 
                selectedCity={removeSpacesAndConvertLowercase(selectedCity)}
            />

            {/* <AppInfo/> replaced with GetTheApp component */}

            {/* <ContactUs gymData={gymData} onSubmit={onSubmit}/> */}
            <ContactUs />

        </div>
    );
}

export default Marketplace;
