import emailjs from 'emailjs-com';
import { useState } from 'react';
import { PillButton } from './ButtonsLibrary';

const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 bg-bg bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-tertiary rounded-lg p-6 text-center shadow-lg">
                <p className="mb-4">{message}</p>
                <button onClick={onClose} className="bg-secondary text-tertiary py-2 px-4 rounded-lg hover:bg-primary transition-all duration-500">
                    Close
                </button>
            </div>
        </div>
    );
};

const ContactUs = ({ gymData, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        city: '',
        message: '',
        category: '' // New field for the selected category
    });

    const [selectedCategory, setSelectedCategory] = useState(''); // For tracking selected category
    const [alertMessage, setAlertMessage] = useState(''); // State to manage alert message
    const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCategoryClick = (value) => {
        setSelectedCategory(value); // Update selected category state
        setFormData({
            ...formData,
            category: value, // Update category in form data
        });
    };

    const sendEmail = (name, email, phone, city, message, category) => {
        const serviceId = 'service_bifr90d'; // Add your service ID
        const templateId = 'template_5iuoinr';
        const userId = 'UghyKy_Cpzm5G5PLp'; // Your EmailJS user ID
    
        const templateParams = {
            from_name: name, // Sender's name (from the form)
            to_name: 'Train Rex', // Recipient's name (you can hardcode or pass dynamically)
            message, // User's message
            phone,
            city,
            category,
            email, // Sender's email
        };
    
        emailjs.send(serviceId, templateId, templateParams, userId)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                // Clear form fields
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    city: '',
                    message: '',
                    category: ''
                });
                // Set alert message and show alert
                setAlertMessage("Thank you for connecting with us!");
                setShowAlert(true);
            })
            .catch((error) => {
                console.error('FAILED...', error);
                // Set alert message for failure
                setAlertMessage("Failed to send the message. Please try again.");
                setShowAlert(true);
            });
    };    

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const { name, email, phone, city, message, category } = formData;
    
        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setAlertMessage("Please enter a valid email address.");
            setShowAlert(true);
            return;
        }
    
        // Phone number validation (exactly 10 digits)
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phone)) {
            setAlertMessage("Please enter a valid 10-digit phone number.");
            setShowAlert(true);
            return;
        }
    
        // Check if category is selected
        if (!category) {
            setAlertMessage("Please select a category.");
            setShowAlert(true);
            return;
        }
    
        // Check if all fields are filled
        if (!name || !email || !phone || !city || !message) {
            setAlertMessage("Please fill out all the fields.");
            setShowAlert(true);
            return;
        }
    
        // If all validations pass, proceed with form submission
        sendEmail(name, email, phone, city, message, category);
    };
    

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <div className="bg-tertiary flex items-center justify-center w-full px-4 py-12 mt-4 md:-mt-20 lg:mt-0 pb-36 md:pb-0 lg:pb-4" style={{height: 600}}>
            <div className="flex flex-col bg-tertiary w-fit lg:w-1/2 rounded-lg">
                <form onSubmit={handleSubmit}> 
                    <h2 className="flex text-2xl lg:text-4xl justify-start md:justify-center font-semibold mb-6 text-primary">Connect with Us!</h2>
                    <div className="mb-4 flex flex-wrap gap-x-2 lg:gap-x-1 justify-between">
                        <PillButton
                            label="Gyms"
                            value="Gyms"
                            onClick={() => handleCategoryClick('Gyms')}
                            selected={selectedCategory === 'Gyms'} 
                        />
                        <PillButton
                            label="Classes"
                            value="Classes"
                            onClick={() => handleCategoryClick('Classes')}
                            selected={selectedCategory === 'Classes'} 
                        />
                        <PillButton
                            label="User"
                            value="User"
                            onClick={() => handleCategoryClick('User')}
                            selected={selectedCategory === 'User'}
                        />
                        <PillButton
                            label="Collaborator"
                            value="Collaborator"
                            onClick={() => handleCategoryClick('Collaborator')}
                            selected={selectedCategory === 'Collaborator'}
                        />
                    </div>
                    <div className="flex flex-row gap-x-4 text-sm md:text-base">
                        <input type="text" id="name" name="name" placeholder="✈️ Full Name" required value={formData.name} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                        <input type="text" id="email" name="email" placeholder="✉️ Email Address" required value={formData.email} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                    </div>
                    <div className="flex flex-row gap-x-4 text-sm md:text-base">
                        <input type="text" id="phone" name="phone" placeholder="☎️ Phone Number" required value={formData.phone} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                        <input type="text" id="city" name="city" placeholder="🗺️ City" required value={formData.city} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                    </div>
                    <div className="flex flex-row gap-x-4 text-sm md:text-base">
                        <textarea id="message" name="message" placeholder="Write a message" required value={formData.message} onChange={handleChange} className="h-20 lg:h-40 px-4 pt-2 rounded-xl bg-tertiary text-primary mb-4 w-full border-4 lg:rounded-2xl border-secondary placeholder-color custom-input"></textarea>
                    </div>
                    <button type="submit" className="bg-secondary text-tertiary py-2 px-4 rounded-2xl hover:bg-primary transition-all duration-200">
                        Submit
                    </button>
                </form>
                {showAlert && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
            </div>
        </div>
    );
};

export default ContactUs;