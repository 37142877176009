import React, { useEffect, useState } from 'react';
import { ClickOpen } from '../ButtonsLibrary';
import { getGymMachineryList } from './GymMachineryHelper';

/**
 * GymDetailsComponent - A component that displays the gym's amenities, equipment, and classes.
 * Each section is displayed in a structured layout with square boxes and multiple columns.
 *
 * @param {Object} gymData  - All the gym details
 * @returns {JSX.Element}   - A structured and styled component displaying gym details.
 */
export const GymDetailsComponent = ({ gymData }) => {
    
    const [equipmentList, setEquipmentList] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        const fetchLists = async () => {
            try {
                const fetchedList = getGymMachineryList(gymData);
                
                setEquipmentList(fetchedList.availableEquipment);
                setFacilities(fetchedList.availableFacilities);
                setClasses(fetchedList.availableClasses);
            } catch (error) {
                console.error("Error fetching gym details : ", error);
            }
        };
    
        fetchLists();
    
    }, [gymData]);
  return (
    <>
      {/* Gym Details Container */}
      <div className="flex flex-col h-fit lg:border-4 border-secondary rounded-2xl p-6 bg-tertiary shadow-lg">
        
        {/* Amenities Section */}
        <ClickOpen heading="Amenities ">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {facilities.map((facility, index) => (
              <div
                key={index}
                className="p-4 bg-secondary border-2 border-primary rounded-lg flex items-center justify-center text-center text-primary font-bold hover:bg-primary hover:text-tertiary"
              >
                {facility}
              </div>
            ))}
          </div>
        </ClickOpen>
        <div className="flex lg:mx-4 border-t-[1px] border-secondary my-4"></div>
        
        {/* Equipment Section */}
        <ClickOpen heading="Equipments ">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {equipmentList.map((equipment, index) => (
              <div
                key={index}
                className="p-4 bg-secondary border-2 border-primary rounded-lg flex items-center justify-center text-center text-primary font-bold hover:bg-primary hover:text-tertiary"
              >
                {equipment}
              </div>
            ))}
          </div>
        </ClickOpen>
        <div className="flex lg:mx-4 border-t-[1px] border-secondary my-4"></div>
        
        {/* Classes Section */}
        <ClickOpen heading="Classes ">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {classes.map((className, index) => (
              <div
                key={index}
                className="p-4 bg-secondary border-2 border-primary rounded-lg flex items-center justify-center text-center text-primary font-bold hover:bg-primary hover:text-tertiary"
              >
                {className}
              </div>
            ))}
          </div>
        </ClickOpen>
        <div className="flex lg:mx-4 border-t-[1px] border-secondary my-4"></div>
      </div>
    </>
  );
};

export default GymDetailsComponent;
