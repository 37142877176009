/**
 * Convert a Google Drive URL to a viewable image URL
 * @param {String} url
 * @returns {String} Reconstruted URL
 */

/**
 * Convert a Google Drive URL to a viewable image URL
 *
 * Example :
 *
 * This : https://drive.google.com/file/d/1jkdgpEh5NT7QushfFKbG7wXZ-6wtxs9N/view
 * To : https://lh3.googleusercontent.com/d/1jkdgpEh5NT7QushfFKbG7wXZ-6wtxs9N=w1000?authuser=1/view
 */

function convertDriveUrl(url) {
  // Extract the file ID using a regular expression
  const fileIdMatch = url.match(/\/d\/(.*?)\//);

  // Check if a valid file ID was found
  if (!fileIdMatch || !fileIdMatch[1]) {
    throw new Error("Invalid Google Drive URL");
  }

  const fileId = fileIdMatch[1];

  // Construct the new URL using the extracted file ID
  const convertedUrl = `https://lh3.googleusercontent.com/d/${fileId}=w1000?authuser=1/view`;

  return convertedUrl;
}

export default convertDriveUrl;
