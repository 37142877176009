import { getCurrencyToDisplay } from "../../PaymentHelpers";

const percentIncreaseOnExclusivePrice = 0.1     // 10% increment

/** 
 * Gets the selling prices for the gym
 * @param {Number} exclusivePrice   -   Exclusive Price of a duration
 * @returns {Number}                -   Price of the gym
 */
const getSellingPrice = (exclusivePrice) => {
    return Math.round(exclusivePrice * (1 + percentIncreaseOnExclusivePrice) - 1);
}

/**
 * Goes Through the List of prices, and
 * Returns the List of selling prices.
 * @param {Object} exclusivePrices - An object containing numerical values of exclusive prices.
 * @returns {Object} - A new object with updated selling prices.
 */
export const getListOfSellingPrices = (exclusivePrices) => {
    const sellingPrices = {};

    // Iterate over each price category and calculate the selling price (price * 1.1)
    for (const key in exclusivePrices) {

        if (typeof exclusivePrices[key] === 'number') {  

            const originalPrice = exclusivePrices[key];
            const sellingPrice = getSellingPrice(originalPrice);  // Calculate the selling price
            sellingPrices[key] = sellingPrice;  // Store the calculated selling price
        }
    }

    return sellingPrices;  // Return the new object with updated prices
};


/**
 * DisplayPrice component to show MRP (with strike-through) and selling price.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.mrp - The MRP price to be displayed with a strike-through.
 * @param {number} props.sellingPrice - The selling price to be displayed in bold.
 * @param {string} props.currency - The currency symbol to display before the prices.
 * @returns JSX Element displaying the MRP and selling price.
 */
export const DisplayPrice = ({ mrp, sellingPrice, country}) => {
    return (
        <span>
            {/* MRP with strike-through */}
            <span className="line-through text-red">
                {getCurrencyToDisplay(country)} {mrp - 1 || "Loading..."}
            </span>
            {" "}
            {/* Selling price in bold */}
            <span className="font-bold">
                {getCurrencyToDisplay(country)} {sellingPrice || "Loading..."}
            </span>
        </span>
    );
};